<app-toolbar-buttons *ngIf="toolbarItems" [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="weldProcedureModelList"
        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true" 
        (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Weld Procedure" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="weld_procedure_id" [width]="50" caption="Weld Procedure Id" [visible]="false"></dxi-column>
        <dxi-column dataField="base_procedure" [width]="120" caption="Base Procedure" [visible]="true"></dxi-column>
        <dxi-column dataField="rev_no" [width]="120" caption="REV" [visible]="true"></dxi-column>
        <dxi-column dataField="weld_procedure_title" [width]="150" caption="Weld Procedure" [visible]="true"></dxi-column>
        <dxi-column caption="Thickness Range" [alignment]="'center'">
             <dxi-column dataField="thickness_start_rng" [width]="150" [alignment]="'center'" caption="Start" [visible]="true"></dxi-column>
             <dxi-column dataField="thickness_end_rng" [width]="150" [alignment]="'center'" caption="End"></dxi-column>
        </dxi-column>
        <dxi-column caption="Hardness Range" [alignment]="'center'">
             <dxi-column dataField="hardness_start_rng" [width]="150" [alignment]="'center'" caption="Start"></dxi-column>
             <dxi-column dataField="hardness_end_rng" [width]="150" [alignment]="'center'" caption="End "></dxi-column>
        </dxi-column>
        <dxi-column caption="Bore Range" [alignment]="'center'">
        <dxi-column dataField="bore_start_rng" [width]="150" [alignment]="'center'" caption="Start"></dxi-column>
        <dxi-column dataField="bore_end_rng" [width]="150" [alignment]="'center'" caption="End" [visible]="true"></dxi-column>
        </dxi-column>
        <dxi-column dataField="disc_title" [width]="100"  caption="Discipline"></dxi-column>
        <dxi-column dataField="sr_code_val" [width]="100" caption="S/R Code"></dxi-column>
        <dxi-column dataField="status_val" [width]="100" caption="Status"></dxi-column>
        <dxi-column dataField="wp_start_date" [width]="100" caption="Start Date" dataType="date" format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="wp_end_date" [width]="100" caption="Closed Date" dataType="date" format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="is_active" [width]="90" caption="is Active?" [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>


        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>