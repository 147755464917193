import * as CryptoJS from 'crypto-js';
export class security {
static key = "650A1EE0-A0F7-4499";

//To encrypt input data
static encrypt(password: string): string {
    return CryptoJS.AES.encrypt(password, this.key).toString();
}

//To decrypt input data
static decrypt(passwordToDecrypt: string) {
    return CryptoJS.AES.decrypt(passwordToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
}
}
