import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { TestProcedureService } from '../../../APIServices/testacceptance/TestProcedureService';
import { utility } from '../../../util';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { SearchProjectjobitemComponent } from "../../common/search-projectjobitem/search-projectjobitem.component";


@Component({
  selector: 'app-test-procedure-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, SearchProjectjobitemComponent],
  templateUrl: './test-procedure-list.component.html',
  styleUrl: './test-procedure-list.component.scss'
})
export class TestProcedureListComponent implements OnInit {
  tModelList: any;
  title: string = 'Test Procedure Maintenance';
  selectedInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Test', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Test', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router, 
    private testProcedureService : TestProcedureService) {    
    
  }

  async ngOnInit(){ 
    await this.Index()
  }

  public async Index() {
    
    // var job_id = 16;
    // var item_id = 19;    

    // (await this.testProcedureService.getAll(job_id, item_id)).subscribe((apiResponse: any) => {
    //   this.tModelList = utility.extractJsonData(apiResponse, 0);
    // });

    this.testProcedureService.getSelectedInfo$.subscribe((x) =>
      this.selectedInfo = x
    );

    if(this.selectedInfo != null){
      var project_id = this.selectedInfo?.project_id;
      var job_id = this.selectedInfo?.job_id;
      var item_id = this.selectedInfo?.item_id;

      (await this.testProcedureService.getAll(project_id,job_id, item_id)).subscribe((apiResponse: any) => {
        this.tModelList = utility.extractJsonData(apiResponse, 0);
      });    

    }
  }

  async onSearch(e: any){

    this.testProcedureService.setSelectedInfo(e);

    var project_id = e?.project_id;
    var job_id = e?.job_id;
    var item_id = e?.item_id;

    (await this.testProcedureService.getAll(project_id,job_id, item_id)).subscribe((apiResponse: any) => {
      this.tModelList = utility.extractJsonData(apiResponse, 0);
    });

  }

  async onResetSearch(e: any){
    this.tModelList = null;
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./testproceduredetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.ps_id;

    let params = {
      ps_id: selectedID
    };
    this.router.navigate(['./testproceduredetails'], { queryParams: params });
    var uModel;
    // if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);

    //   var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //   uModel.site_id = loggedInUser.site_id;
    //   uModel.site_code = loggedInUser.site_code;
    //   uModel.site_description = loggedInUser.site_description;
    // }
    // else {
    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);
    // }
    
      var detailData = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(detailData);      
      this.testProcedureService.setSelectedInfo(uModel);
      //this.router.navigate(['./processsdetails'], { queryParams: params });   
      e.event?.preventDefault();
  };

  RouteToCreate(){
    
  }

  onSelectionChanged(event: any){

  }

  DeActivate(){

  }


}
