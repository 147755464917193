<div>
    <form [formGroup]="sForm" novalidate class="form">
        <table>
            <tbody>
                <tr>
                    <td></td>
                    <td style="width:10%">
                        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
                            <mat-label>Select Project</mat-label>
                            <mat-select formControlName="project_id" (selectionChange)="onProjectChange_populateJobListByProject($event)">
                                <ng-container>
                                    @for (Item of pModelList; track Item) {
                                    <mat-option [value]="Item.project_id">{{Item.project_code + ' - ' + Item.project_title}}</mat-option>
                                    }
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width:10%; padding-left: 10px;">
                        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
                            <mat-label>Job</mat-label>
                            <mat-select formControlName="job_id" (selectionChange)="onJobChange_populateItemListByJob($event)">
                                <ng-container>
                                    @for (Item of jModelList; track Item) {
                                    <mat-option [value]="Item.job_id">{{Item.job_code + ' - '  + Item.job_title}}</mat-option>
                                    }
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width:10%; padding-left: 10px;">
                        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
                            <mat-label>Item</mat-label>
                            <mat-select formControlName="item_id" (selectionChange)="ItemSelectionChange($event)">
                                <ng-container>
                                    @for (Item of iModelList; track Item) {
                                    <mat-option [value]="Item.item_id">{{Item.item_code + ' - '  + Item.item_title}}</mat-option>
                                    }
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width:10%; padding-left: 10px;">
                        <button mat-raised-button color="primary" [disabled]="!sForm.valid" (click)="onSearch()">Search</button>                    
                    </td>
                    <td></td>
                    
                </tr>
            </tbody>
        </table>
    </form>
</div>

