<mat-toolbar class="mat-elevation-z1" style="margin-bottom: 10px;">
    <span class="span-page-title">{{title}}-[{{site_desc}}]</span>
    <span class="example-spacer"></span>
    <div style="float: right;">
        @for(button of namedButtons; track button){
        <button mat-button (click)="button.action()" [hidden]="button.hidden" style="margin-right:5px">
            {{button.label}}
        </button>
        }
        <!-- <button mat-icon-button *ngFor="let button of iconButtons" 
         [matTooltip]="button.label" (click)="button.action()" [hidden]="button.hidden">
        <mat-icon>{{button.iconName}}</mat-icon>
    </button> -->
        <span fxFlex></span>
        <button mat-icon-button [matMenuTriggerFor]="overflowMenu" class="overflowMenu"
            *ngIf="overflowMenuItems.length">
            <mat-icon>menu_open</mat-icon>
        </button>
        <mat-menu #overflowMenu="matMenu" [overlapTrigger]="false">
            @for(item of overflowMenuItems; track item){
            <button mat-menu-item color="primary" *ngIf="!item.hidden" (click)="item.action()">
                <!-- <mat-icon>{{item.iconName}}</mat-icon> -->
                <span>{{item.label}}</span>
            </button>
            }
        </mat-menu>
    </div>
</mat-toolbar>