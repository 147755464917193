import { Component, Input, OnInit, signal } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from "../menu-item/menu-item.component";

export type MenuItem = {
  display_name?: string;
  isSideNavItem?: string;
  isTopNavItem?: string;
  menu_id?: number;
  menu_name?: string;
  module_code?: string;
  module_name?: string;
  parent_id?: number;
  route?: string;
  children?: MenuItem[];
  icon_name?: string;
}

@Component({
  selector: 'app-custom-sidenav',
  standalone: true,
  imports: [MaterialModule, MenuItemComponent, RouterModule],
  template: `
            <mat-nav-list>
              @for (item of menuItem; track item.menu_name) {
                <app-menu-item [item] ="item" [snav]="snav"/>
              }
            </mat-nav-list>
            `,
  styles: [`
        .menu-item {
          border-left: 5px solid;
          border-left-color: rgba(0,0,0,0);
        }
        .selected-menu-item {
          border-left-color: blue;
          background: rgba(0,0,0,0.05);
        }
    `
  ]

})

export class CustomSidenavComponent {
  @Input() menuItem;
  @Input() snav;

}
