<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Process Group</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="pgForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocessgroupid" name="text" class="matInputCls"
                                    formControlName="process_group_id">
                            </mat-form-field>
                        </div>
                        <!-- Code -->
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix mt-1e asterix">Code</mat-label>                                                        
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocessgroupcode" name="text" class="matInputCls toUpperCase"
                                    formControlName="process_group_code" Maxlength="10" appOnlyAlphanumeric>                                
                                <mat-error class="text-danger" *ngIf="pgForm.controls['process_group_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>                            
                        </div>
                        <!-- Description -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Description</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtprocessgrouptitle" name="text" class="matInputCls"
                                    formControlName="process_group_title" maxlength="255">
                                <mat-error class="text-danger"
                                    *ngIf="pgForm.controls['process_group_title'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>                    
                        
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label> 
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                    id="chkIsActive">
                            </mat-checkbox>                 
                        </div> 
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>