import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from '../../../core/material-module';
import { CommonModule, DOCUMENT } from '@angular/common';
import { utility } from '../../../util';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectTestsService } from '../../../APIServices/ProjectTestsService';
import { LookupService } from '../../../APIServices/LookupService';

@Component({
  selector: 'app-add-edit-tests',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './add-edit-tests.component.html',
  styleUrl: './add-edit-tests.component.scss'
})
export class AddEditTestsComponent {
  pTestsForm: FormGroup;
  pTestValidation: any[];
  activeStatus = utility.activeStatus;
  selectedInfo: any;
  lookupTestProcedure: any;
  lookupAcceptanceCriteria: any;
  lookupTestsType: any;
  localStorage: any;
  loggedInUser:any;
  projectTestModel: any;
  disableTestType: boolean = false;

  constructor(private dialogRef: MatDialogRef<AddEditTestsComponent>,
              private router: Router,
              private projectTestsService: ProjectTestsService,
              private lookupService: LookupService,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.localStorage = document.defaultView?.localStorage;
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    console.log('loggedInUser:', this.loggedInUser);
    
   // await this.fetchTestsType();


      this.projectTestsService.getSelectedInfo.subscribe(x => this.selectedInfo = x);
      await this.fetchTestsType();
      console.log("selectedInfo:", this.selectedInfo);
      if (this.selectedInfo.ti_id == null || this.selectedInfo.ti_id == 0
        || this.selectedInfo.ti_id == undefined) {
        this.disableTestType = false;
      } else {
        this.disableTestType = true;
      }
    if (this.selectedInfo != null && this.selectedInfo != undefined) {
      this.projectTestModel = Object.assign({}, this.selectedInfo);
      this.initializeForm(this.selectedInfo);
    }
  }

  async fetchTestsType() {
    console.log('selcted Info in add test', this.selectedInfo);
    (await this.lookupService.getTestsTypes(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupTestsType = utility.extractJsonData(apiResponse, 0);
      console.log('Test Type', this.lookupTestsType)
      if(this.projectTestModel != null) {
        this.fetchTestProcedure(this.projectTestModel.ps_id);
      }      
    });
  }

  async fetchTestProcedure(e) {
    var currentSelectedTestId = this.projectTestModel.test_id ? this.projectTestModel.test_id : this.pTestsForm.value['test_id'];
    console.log('currentSelectedTestId', currentSelectedTestId);;
    if(currentSelectedTestId != null && currentSelectedTestId != undefined && currentSelectedTestId != '')
    {
        (await this.lookupService.getTestProcedure(
            this.loggedInUser.logon_id, 
            this.projectTestModel.site_id,
            this.projectTestModel.job_id,
            this.projectTestModel.item_id,
            this.projectTestModel.pd_id,
            currentSelectedTestId
          )).subscribe((apiResponse: any) => {
          this.lookupTestProcedure = utility.extractJsonData(apiResponse, 0);
          if(this.projectTestModel != null) {
            this.fetchAcceptanceCriteria(this.projectTestModel.ac_id);
          }
        });
    }
  }

  async fetchAcceptanceCriteria(e) {
    var currentSelectedTestId = this.projectTestModel.test_id ? this.projectTestModel.test_id : this.pTestsForm.value['test_id'];
    (await this.lookupService.getAcceptanceCriteria(
      this.loggedInUser.logon_id,
      this.projectTestModel.site_id,
      this.projectTestModel.job_id,
      this.projectTestModel.item_id,
      this.projectTestModel.pd_id,
      currentSelectedTestId
    )).subscribe((apiResponse: any) => {
      this.lookupAcceptanceCriteria = utility.extractJsonData(apiResponse, 0);
    });
  }

  saveTestsInfo() {
    this.pTestsForm.markAllAsTouched();
    const isFormValid = this.pTestsForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.pTestsForm.value['ti_id'] == null) {
      this.createTests();
    }
    else {
      this.updateTests();
    }
  }

  async createTests() {
    this.pTestsForm.value['requester_id'] = this.loggedInUser.logon_id;

    (await this.projectTestsService.createProjectTests(this.pTestsForm.value)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  async updateTests() {
    this.pTestsForm.value['requester_id'] = this.loggedInUser.logon_id;

    (await this.projectTestsService.updateProjectTests(this.pTestsForm.value)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  async initializeForm(pTestsInfo: any) {
    if (pTestsInfo != null) {
      this.pTestsForm = new FormGroup({
        ti_id: new FormControl(pTestsInfo?.ti_id),
        job_id: new FormControl(pTestsInfo?.job_id),
        item_id: new FormControl(pTestsInfo?.item_id),
        site_id: new FormControl(pTestsInfo?.site_id),
        disc_id: new FormControl(pTestsInfo?.disc_id),
        disc_code: new FormControl(pTestsInfo?.disc_code),
        project_id: new FormControl(pTestsInfo?.project_id),
        job_code: new FormControl(pTestsInfo?.job_code),
        pd_id: new FormControl(pTestsInfo?.pd_id),
        test_type: new FormControl(pTestsInfo?.test_type),
        test_code: new FormControl( pTestsInfo?.test_code),
        test_id: new FormControl({value: pTestsInfo?.test_id, disabled: this.disableTestType}, [Validators.required]),
        ps_id: new FormControl(pTestsInfo?.ps_id, [Validators.required]),
        ac_id: new FormControl(pTestsInfo?.ac_id, [Validators.required]),
        test_procedure: new FormControl(pTestsInfo?.test_procedure),
        acceptance_criteria: new FormControl(pTestsInfo?.acceptance_criteria),
        report_counter: new FormControl(pTestsInfo?.report_counter)
      });
    }
    else {
      this.pTestsForm = new FormGroup({
        ti_id: new FormControl(''),
        job_id: new FormControl(''),
        item_id: new FormControl(''),
        disc_id: new FormControl(''),
        site_id: new FormControl(''),
        disc_code: new FormControl(''),
        project_id: new FormControl(''),
        job_code: new FormControl(''),
        test_type: new FormControl(''),
        test_procedure: new FormControl(''),
        acceptance_criteria: new FormControl(''),
        report_counter: new FormControl(''),
        test_code: new FormControl(''),
        test_id: new FormControl({value: '', disabled: this.disableTestType}),
        ps_id: new FormControl(''),
        ac_id: new FormControl(''),
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
