import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { utility } from "../util";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiBaseUrl: string;
  private environmentConfig = utility.getEnvironmentData();
  private selectedInfo = new BehaviorSubject(null);
  getSelectedInfo = this.selectedInfo.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiBaseUrl = this.environmentConfig.apiBase;
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  // getSelectedUserInfo() {
  //   return this.selectedUserInfo;
  // }
  setSelectedInfo(paramData: any) {
    this.selectedInfo.next(paramData);
  }
  public async getAllUserList(requester_id: any, site_id: any) {
    var apiMethod = "UserInfo/GetAll";
    var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
    var url = this.apiBaseUrl + apiMethod + param;
    return this.httpClient.get(url).pipe(
      catchError(this.errorHandler)
    );
  }
  public async getSelectedUser(requester_id: any, user_id: any) {
    var apiMethod = "UserInfo/detail";
    var param = "?requester_id=" + requester_id + "&user_id=" + user_id;
    var url = (this.apiBaseUrl + apiMethod) + param;
    return this.httpClient.get(url).pipe(
      catchError(this.errorHandler)
    );
  }
  public async getLoggedInUserInfo(requester_id: any, logon_id: any) {
    var apiMethod = "UserInfo/detail";
    var param = "?requester_id=" + requester_id + "&logon_id=" + logon_id;
    var url = (this.apiBaseUrl + apiMethod) + param;
    return this.httpClient.get(url).pipe(
      catchError(this.errorHandler)
    );
  }
  public async createUser(post: any) {
    var apiMethod = "UserInfo/Create";
    var url = (this.apiBaseUrl + apiMethod)
    return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  public async updateUser(put: any) {
    var apiMethod = "UserInfo/Update";
    var url = (this.apiBaseUrl + apiMethod)
    return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public async deleteUser(del: any) {
    var apiMethod = "UserInfo/Delete";
    var url = (this.apiBaseUrl + apiMethod);
    return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public async mapUserToSite(put: any) {
    var apiMethod = "UserInfo/MapUserToSite";
    var url = (this.apiBaseUrl + apiMethod)
    console.log(JSON.stringify(put));
    return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public async mapUserToGroup(put: any) {
    var apiMethod = "UserInfo/MapUserToGroup";
    var url = (this.apiBaseUrl + apiMethod)
    console.log(JSON.stringify(put));
    return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  public async mapUserToProject(put: any) {
    var apiMethod = "UserInfo/MapUserToproject";
    var url = (this.apiBaseUrl + apiMethod)
    console.log(JSON.stringify(put));
    return this.httpClient.post(url, JSON.stringify(put), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }
  
  public async getCountryCode(requester_id: any) {
    var apiMethod = "lookup/CountryCode";
    var param = "?requester_id=" + requester_id;
    var url = (this.apiBaseUrl + apiMethod) + param;
    return this.httpClient.get(url).pipe(
        catchError(this.errorHandler)
    );
}
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}