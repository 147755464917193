import { Component } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-about-page',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './about-page.component.html',
  styleUrl: './about-page.component.scss'
})
export class AboutPageComponent {
  baseUrl = environment.baseURL;
}
