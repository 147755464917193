import { inject, NgModule } from "@angular/core";
import { MSAL_INSTANCE, MsalModule, MsalService } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { ENVIRONMENT_CONFIG } from "../environment.config";
import { utility } from "../util";

export function MSALInstanceFactory(): IPublicClientApplication {
    var environmentConfig = utility.getEnvironmentData();
    return new PublicClientApplication({
        auth: {
            clientId: environmentConfig.clientId,
            authority: environmentConfig.authority,
            redirectUri: environmentConfig.redirectUri,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: environmentConfig.redirectUri,
        }
    })
}

@NgModule({
    declarations: [],
    imports: [MsalModule],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        MsalService
    ]
})
export class MSAL_Module {
}