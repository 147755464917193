<form [formGroup]="pDisciplineForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Discipline</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="pd_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Discipline</mat-label>
                <mat-select formControlName="disc_id">
                    <ng-container>
                        @for (Item of lookupDisciplineList; track Item) {
                        <mat-option [value]="Item.disc_id">{{Item.disc_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="pDisciplineForm.controls['disc_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container">
            <mat-checkbox formControlName="is_active" class="matInputCls" id="chkIsActive">
                Is Active
            </mat-checkbox>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="saveDiscInfo()">Save</button>
    </mat-dialog-actions>
</form>