import { Injectable } from "@angular/core";
import { utility } from "../../../util";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class MaterialTypeService {
  requester_id: string;
  site_id: string;
  site_code : string;
  apiBaseUrl: string;
  apiBaseUrl1: string;

  apiEndPoint: string = "materialtypeinfo/"
  private environmentConfig = utility.getEnvironmentData();

  private selectedInfo = new BehaviorSubject<any>(null);
  getSelectedInfo$ = this.selectedInfo.asObservable();

  constructor(
        private httpClient: HttpClient,
        private router: Router) { 

        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id      
        this.apiBaseUrl = this.environmentConfig.weldApiBase; //environment.weldApiBase;     
  }

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }
  
  setSelectedInfo(paramData: any) {
    this.selectedInfo.next(paramData)
  }

  getAllMaterialType(){
    //this.site_id = "1";
    const params = new HttpParams()
      //.set('requester_id',this.requester_id)
      .set('site_id',this.site_id)

    var apiMethod = this.apiEndPoint + "getall";
    var url = (this.apiBaseUrl +  apiMethod); // + param;
    return this.httpClient.get(url,{params: params});
  }

  public async getMaterialTypeDetails(material_type_id: any) {

    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('material_type_id',material_type_id)

    var apiMethod = this.apiEndPoint + "detail";
    //var param = "?requester_id=" + requester_id + "&project_id=" + project_id + "&site_id=" + site_id;
    var url = (this.apiBaseUrl + apiMethod); // + param;
    return this.httpClient.get(url,{params: params});    
  }

  public async saveMaterialType(data : any){
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(data), this.httpOptions)        
  }

  public async updateMaterialType(data : any){
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions).pipe(
          catchError(this.errorHandler)
      );
      
  }

  public async deleteMaterialType(data : any){
    data.requester_id = this.requester_id;
    data.site_id = this.site_id;
    data.site_code = this.site_code;

    var apiMethod = this.apiEndPoint + "Delete";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(data), this.httpOptions).pipe(
          catchError(this.errorHandler)
      );
      
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
    } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}