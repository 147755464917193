import { Component } from '@angular/core';
import { MaterialModule } from '../material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { security } from '../../security';

@Component({
  selector: 'app-data-encryption',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule],
  templateUrl: './data-encryption.component.html',
  styleUrl: './data-encryption.component.scss'
})
export class DataEncryptionComponent {
  dForm: FormGroup;
  inputData:any;
  resultData:any;

  EncryptData() {
    var data = this.inputData;
    var iResult = security.encrypt(data);
    this.resultData = iResult;
  }
  DecryptData() {
    var data = this.inputData;
    var iResult = security.decrypt(data);
    this.resultData = iResult;
  }
  ClearData() {
    this.inputData='';
    this.resultData='';
  }
}
