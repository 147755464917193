import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { utility } from "../util";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private apiBaseUrl: string;
    private environmentConfig = utility.getEnvironmentData();
    constructor(private httpClient: HttpClient) {
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }
    public async getAuthMenus(loggedInUser: any) {
        var apiMethod = "UserAuth/Detail";
        var param = "?requester_id=" + loggedInUser + "&logon_id=" + loggedInUser;
        var url = (this.apiBaseUrl + apiMethod) + param;
        return this.httpClient.get(url);
    }
}