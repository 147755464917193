import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../APIServices/ProjectService';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module'
import { ActivatedRoute, Router } from '@angular/router';
import { projectModel } from '../../../models/projectModel';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { UtilityService } from '../../../APIServices/UtilityService';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-project-list-register',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss'
})
export class ProjectListComponent implements OnInit {

  dModelList: any;
  selectedInfo: any;
  title: any = "Project Management";
  loggedInUser: any;
  buttonAccess: any = [];
  toolbarItems:any;
  currentUrl: string;

  constructor(
              private projectService: ProjectService, 
              private router: Router,
              private utilService: UtilityService,
              private route: ActivatedRoute) {
              
  }
  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    await this.Index();
  }

  public async Index() {
    
    
    (await this.projectService.getUserWiseProjectList(this.loggedInUser.logon_id, this.loggedInUser.site_id, this.loggedInUser.user_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonInitialization(resData[0]);
    });
}

 buttonInitialization(buttonAccess: any) {
    console.log('buttonAccess',buttonAccess);
    this.toolbarItems = [
      { label: 'Add Project', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: !buttonAccess.can_add },
      { label: 'Inactivate Project', path: '', iconName: '', action: () => this.deleteProject(), hidden: !buttonAccess.can_delete },
    ];
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./projectdetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.project_id;

    let params = {
      project_id: selectedID
    };
    this.router.navigate(['./projectdetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);

      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

      uModel.site_id = loggedInUser.site_id;
      uModel.site_code = loggedInUser.site_code;
      uModel.site_description = loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
    this.projectService.setSelectedInfo(uModel);
    e.event?.preventDefault();
  };
  RouteToCreate() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var pModel = {
      site_id: loggedInUser.site_id,
      site_code: loggedInUser.site_code,
      site_description: loggedInUser.site_description
    };

    this.projectService.setSelectedInfo(pModel);
    this.router.navigate(['./projectdetails']);
  }
  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.projectService.setSelectedInfo(this.selectedInfo);
  }

  async deleteProject() {
    if (this.selectedInfo != null && this.selectedInfo.length == undefined) {
      alert("Please select a project");
      return;
    }

    if (!confirm("Are you sure to delete the project?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    let params = {
      project_list: this.selectedInfo,
      site_id: loggedInUser.site_id,
      requester_id: loggedInUser.logon_id
    };

    (await this.projectService.deleteProject(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
}
