import { MediaMatcher } from '@angular/cdk/layout';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Component, Input, NO_ERRORS_SCHEMA, OnDestroy } from '@angular/core';
import { MaterialModule } from './../../core/material-module'
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MenuService } from '../../APIServices/MenuService';
import { utility } from '../../util';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../../APIServices/UserService';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomSidenavComponent } from "../../home/custom-sidenav/custom-sidenav.component";
import { security } from '../../security';
@Component({
  selector: 'app-top-nav-bar',
  standalone: true,
  // imports: [MatToolbarModule, MatButtonModule, MatIconModule, MatSidenavModule, MatListModule],
  imports: [MaterialModule, RouterOutlet, FlexLayoutModule, CustomSidenavComponent],
  templateUrl: './top-nav-bar.component.html',
  styleUrl: './top-nav-bar.component.scss',
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class TopNavBarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  authmenu: any;
  isSignedIn = false;
  private environmentConfig = utility.getEnvironmentData();
  baseUrl = this.environmentConfig.baseURL;
  loggedInUser: any;

  constructor(
      changeDetectorRef: ChangeDetectorRef,
      media: MediaMatcher,
      private menuService: MenuService,
      private msalservice: MsalService,
      private userService: UserService,
      private router: Router) {
          this.mobileQuery = media.matchMedia('(max-width: 600px)');
          this._mobileQueryListener = () => changeDetectorRef.detectChanges();
          this.mobileQuery.addListener(this._mobileQueryListener);
  }
  async ngOnInit(): Promise<void> {
  //  window.addEventListener('beforeunload', this.logout);
    await this.msalservice.instance.initialize();

    if (this.isLoggedIn()) {
      this.Index();
    }

    this.msalservice.instance.handleRedirectPromise().then(
      res => {
        if (res != null && res.account != null) {
          this.msalservice.instance.setActiveAccount(res.account)
          this.saveUserInfoOnLogonFromAzureAD();
          this.getLoggedInUserInfo();
          this.Index();
        }
      }
    )

     this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    // this.getSiteNameInfo();
  }
  public async Index() {
    var userEmail = this.msalservice.instance.getActiveAccount()?.username;
    (await this.menuService.getAuthMenus(userEmail)).subscribe((apiResponse: any) => {
      const jData = utility.extractJsonData(apiResponse, 0);
      var rows: any;
      if (jData != null)
        rows = Object.values(jData);
      this.authmenu = rows;
    });
  }

  getName(): any {
    return this.msalservice.instance.getActiveAccount()?.name;
  }

  getSiteNameInfo() {
    if (this.loggedInUser) {
      return this.loggedInUser.site_code;
    }
  }

  logout() {
   // localStorage.removeItem('loggedInUser');
    const account = this.msalservice.instance.getActiveAccount();
    this.msalservice.logoutRedirect({ account: account });
   // localStorage.clear();
  }
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from(
    { length: 50 },
    () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  );

  private _mobileQueryListener: () => void;
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // window.removeEventListener('beforeunload', this.logout);
    this.logout();
  }

  shouldRun = "";

  isLoggedIn(): boolean {
    return this.msalservice.instance.getActiveAccount() != null
  }

  async login() {
    this.msalservice.loginRedirect();
  }
  filterByParentID(parent_id: number) {
    return this.authmenu.filter(x => x.parent_id == parent_id);
  }
  filterByParentHasChild(menu_id: number) {
    return this.authmenu.filter(x => x.parent_id == menu_id).length;
  }
  async saveUserInfoOnLogonFromAzureAD() {
    var uAcc = this.msalservice.instance.getActiveAccount();
    var uModel: any;
    uModel = {
      logon_id: uAcc?.username,
      full_name: uAcc?.name,
      user_email: uAcc?.username,
      requester_id: uAcc?.username,
    };

    (await this.userService.createUser(uModel)).subscribe(res => {
    });
  }

  async getLoggedInUserInfo() {
    var uAcc = this.msalservice.instance.getActiveAccount();
    (await this.userService.getLoggedInUserInfo(uAcc?.username, uAcc?.username)).subscribe((apiResponse: any) => {
      const jData = utility.extractJsonData(apiResponse, 0);
      var rows: any;

      if (jData != null)
        rows = JSON.stringify(jData[0]);
      else {
        var uModel =
        {
          logon_id: uAcc?.username,
          full_name: uAcc?.name
        };
        rows = JSON.stringify(uModel);
      }
      var encryptData = security.encrypt(rows);
      localStorage.setItem('loggedInUser', encryptData);
    });
  }

  openNotification() {
    this.router.navigate(['./notificationpage']);
  }
  openAbout() {
    this.router.navigate(['./aboutpage']);
  }
  openFAQ() {
    this.router.navigate(['./faqpage']);
  }
  openHomeMenu() {
    this.router.navigate(['./homemenus']);
  }
}
