<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Employee Type Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="eForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtemployeetypeID" name="text" class="matInputCls"
                                    formControlName="employee_type_id" maxlength="120">
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix mt-1e">Employee Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtemployeetypeCode" name="text" class="matInputCls toUpperCase"
                                    formControlName="employee_type_code" Maxlength="20" appOnlyAlphanumeric>
                                <mat-error class="text-danger" *ngIf="eForm.controls['employee_type_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Employee Type Title</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtemployeetypetitle" name="text" class="matInputCls"
                                    formControlName="employee_type_title" maxlength="20">
                                <mat-error class="text-danger"
                                    *ngIf="eForm.controls['employee_type_title'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label> 
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                    id="chkIsActive">
                            </mat-checkbox>                 
                        </div> 
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>