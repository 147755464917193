import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SearchProjectJobItemService } from '../../../APIServices/SearchProjectJobItemService';
import { utility } from '../../../util';

@Component({
  selector: 'app-search-projectjobitem',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule],
  templateUrl: './search-projectjobitem.component.html',
  styleUrl: './search-projectjobitem.component.scss'
})
export class SearchProjectjobitemComponent implements OnInit {
  pModelList: any;
  jModelList: any;
  iModelList: any;
  param_project_id : any;
  param_job_id : any;
  param_item_id : any;

  sForm: FormGroup;

  @Output()
  search = new EventEmitter<void>();

  @Output()
  resetSearch = new EventEmitter<void>();

  searchCriteria: any;  

  /**
   *
   */
  constructor(private searchProjectJobItemService: SearchProjectJobItemService) {      
    this.initializeForm(null);
  }
  
  async ngOnInit() {
    this.populateProjectList()
  }

  initializeForm(pInfo: any){
    
    this.pModelList = null
    this.jModelList = null
    this.iModelList = null
    this.param_project_id = null
    this.param_job_id = null
    this.param_item_id = null;
    
    this.sForm = new FormGroup({
      project_id: new FormControl(null,[Validators.required]),
      job_id: new FormControl(null,[Validators.required]),
      item_id: new FormControl(null,[Validators.required])
    })
  }

  async Reset(){
    this.jModelList = null
    this.iModelList = null
    this.param_project_id = null;
    this.param_job_id = null;
    this.param_item_id = null;

    this.sForm.get('job_id')?.reset(null);
    //this.sForm.get('job_id')?.updateValueAndValidity();    
    this.sForm.get('item_id')?.reset(null);
    //this.sForm.get('item_id')?.updateValueAndValidity();    
  }

  async populateProjectList(){
    (await this.searchProjectJobItemService.getUserWiseProjectList()).subscribe((apiResponse: any) => {
      this.pModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async onProjectChange_populateJobListByProject(e: any){  
    
    this.Reset();

    // console.log('Form Valid ?');
    // console.log(this.sForm.valid);
    // console.log(this.sForm.pristine);
    // console.log(this.sForm.get('job_id')?.value)

    if(e != null && e != 'undefined' && e != '')
    {
         this.param_project_id = e.value;        
        (await this.searchProjectJobItemService.getJobListByProject(this.param_project_id)).subscribe((apiResponse: any) => {
          this.jModelList = utility.extractJsonData(apiResponse, 0);
        });
    }
    this.resetSearchCriteria_event();

  }

  async onJobChange_populateItemListByJob(e: any){
    this.iModelList = null
    this.sForm.get('item_id')?.reset(null);

    if(e != null && e != 'undefined' && e != ''){
           this.param_job_id = e.value;        
          (await this.searchProjectJobItemService.getItemListByJOB(this.param_project_id,this.param_job_id)).subscribe((apiResponse: any) => {
            this.iModelList = utility.extractJsonData(apiResponse, 0);
          });
    }
    this.resetSearchCriteria_event();
  }

  async ItemSelectionChange(e: any){
    if(e != null && e != 'undefined' && e != ''){
           this.param_item_id = e.value;          
    }    
  }

  onSearch(){

    this.sForm.markAllAsTouched();
    if (!this.sForm.valid){
      return
    }

    this.searchCriteria = {
      project_id: this.param_project_id,
      job_id : this.param_job_id,
      item_id : this.param_item_id  
    }

    //console.log(this.searchCriteria);
    this.search.emit(this.searchCriteria);
  }

  resetSearchCriteria_event(){
    this.resetSearch.emit();
  }

}
