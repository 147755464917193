import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';
import { utility } from '../../../../../util';
import { WorkLocationService } from '../../../../../APIServices/master/weld/work-location.service';

@Component({
  selector: 'app-worklocation-list',
  standalone: true,
  imports: [MaterialModule, DevExtremeModule, ToolbarButtonsComponent],
  templateUrl: './worklocation-list.component.html',
  styleUrl: './worklocation-list.component.scss'
})
export class WorklocationListComponent implements OnInit {

  dModelList: any;
  title: string = 'Work Location';
  selectedInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Location', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Location', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router, 
    private workLocationService : WorkLocationService) {
        
  }

  async ngOnInit(){ 
    await this.Index()
  }

  public async Index() {
  
    (await this.workLocationService.getAll()).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.workLocationService.setSelectedInfo(this.selectedInfo);
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./worklocationdetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.location_id;

    let params = {
      location_id: selectedID
    };
    this.router.navigate(['./worklocationdetails'], { queryParams: params });
    var uModel;
    // if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);

    //   var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //   uModel.site_id = loggedInUser.site_id;
    //   uModel.site_code = loggedInUser.site_code;
    //   uModel.site_description = loggedInUser.site_description;
    // }
    // else {
    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);
    // }
    
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);      
      this.workLocationService.setSelectedInfo(uModel);
      this.router.navigate(['./worklocationdetails'], { queryParams: params });   
      e.event?.preventDefault();
  };

  RouteToCreate(){
    this.workLocationService.setSelectedInfo(null);
    this.router.navigate(['./worklocationdetails']);
  }

  async DeActivate(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Work Location record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Work Location?")) {
      return;
    }

    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //var empTypeList = this.selectedInfo;
    let params = {
      work_location_list: this.selectedInfo
    };

    // var userInfoDelete = {
    //   userList: this.selectedInfo//,
    //   //requester_id: loggedInUser.logon_id
    // };

    (await this.workLocationService.Delete(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
}
