import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from 'devextreme-angular';
import { MaterialModule } from '../../../../../core/material-module';
import { FormsModule } from '@angular/forms';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { LineClassService } from '../../../../../APIServices/master/weld/LineClassService';
import { utility } from '../../../../../util';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-line-class-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, FormsModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './line-class-list.component.html',
  styleUrl: './line-class-list.component.scss'
})
export class LineClassListComponent implements OnInit {
  title: any = "Line Class Master";
  selectedInfo: any;
  pModelList: any[] = [];
  dModelList: any[] = [];
  selectedProject: any;
  loggedInUser: any;

  constructor(private lineClassService: LineClassService, private router: Router) { }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.Index();
  }

  public async Index() {
    await this.populateProject();
  }
  async filterByProject() {
    (await this.lineClassService.getAllLineClassList(this.selectedProject)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  async populateProject() {
    (await this.lineClassService.getActiveProjectList()).subscribe((apiResponse: any) => {
      this.pModelList = utility.extractJsonData(apiResponse, 0);
      if (this.pModelList != null && this.pModelList.length > 0) {
        this.selectedProject = this.pModelList[0].project_id;
        this.filterByProject();
      }
    });
  }
  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.lineClassService.setSelectedInfo(this.selectedInfo);
  }
  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./lineclassdetails']);
    }
    var selectedID = e.row?.data.lineclass_id;
    var selectedProjID = e.row?.data.project_id;
    let params = {
      lineclass_id: selectedID,
      project_id: selectedProjID
    };

    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
    this.lineClassService.setSelectedInfo(uModel);
    this.router.navigate(['./lineclassdetails'], { queryParams: params });
    e.event?.preventDefault();
  }
  async routeToCreate() {
    if (this.selectedProject == null || this.selectedProject == undefined) {
      alert('Please select a project');
      return;
    }
    var pModel = {
      site_id: this.loggedInUser.site_id,
      site_code: this.loggedInUser.site_code,
      site_description: this.loggedInUser.site_description,
      project_id: this.selectedProject
    };

    this.lineClassService.setSelectedInfo(pModel);
    this.router.navigate(['./lineclassdetails']);
  }
  async deleteLineClass(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Line Class Record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Line Class?")) {
      return;
    }

    let params = {
      site_id:this.loggedInUser.site_id,
      project_id:this.selectedProject,
      line_class_list: this.selectedInfo
    };


    (await this.lineClassService.deleteLineClass(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Line Class', path: '', iconName: '', action: () => this.routeToCreate(), hidden: false },
    { label: 'Inactivate Line Class', path: '', iconName: '', action: () => this.deleteLineClass(), hidden: false }
  ];
}
