import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { utility } from "../util";
import { Router } from '@angular/router';
import { Observable, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    private apiBaseUrl: string;
    private environmentConfig = utility.getEnvironmentData();
  requester_id: any;
  site_id: any;
  user_id: any;
  menu_id: any;
    constructor(private httpClient: HttpClient, private router: Router) {
        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id;
        this.site_id = loggedInUser.site_id;
        this.user_id = loggedInUser.user_id;
       // this.menu_id = localStorage.getItem('current_menu_id');//loggedInUser.menu_id;
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }

    httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    }

   /*  public async getButtonAccess(currRoute) {
      (await this.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
        return utility.extractJsonData(apiResponse, 0);
      });
    } */
    public getAllButtonAccess(route:any) {
      const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('user_id',this.user_id)
        //.set('menu_id',this.menu_id);
        .set('route',route);
      var apiMethod = "UserInfo/UserAccessDetail";
      //var param = "?requester_id=" + logon_id + "&site_id=" + site_id + "&route=" + route + "&user_id=" + user_id;
      var url = this.apiBaseUrl + apiMethod;
      return this.httpClient.get(url,{params: params}); 
    }

      errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.message;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }
}