import { Injectable } from '@angular/core';
import { utility } from '../util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchProjectJobItemService {
  requester_id: string;
  site_id: string;
  site_code : string;
  apiBaseUrl: string;
  user_id: string;
  
  private environmentConfig = utility.getEnvironmentData();

  private selectedSearchParamInfo = new BehaviorSubject<any>(null);
  getSearchParamInfo$ = this.selectedSearchParamInfo.asObservable();

 
  constructor(private httpClient: HttpClient,
              private router: Router
  ) { 
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id;
      this.site_id = loggedInUser.site_id;
      this.user_id = loggedInUser.user_id;
      this.apiBaseUrl = this.environmentConfig.apiBase; //environment.weldApiBase;
  }

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }

  setSelectedInfo(paramData: any) {
    this.selectedSearchParamInfo.next(paramData)
  }

  public async getUserWiseProjectList() {  
      const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('user_id',this.user_id)
        .set('is_active',true)

      var apiMethod = "ProjectInfo/" + "getall";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
  }

  public async getJobListByProject(project_id: any) {
    const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('user_id',this.user_id)
        .set('project_id',project_id)
        .set('is_active',true)
 
        var apiMethod = "ProjectJobInfo/" + "getall";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url,{params: params});
  }

  public async getItemListByJOB(project_id: any , job_id: any) {
    const params = new HttpParams()
        .set('requester_id',this.requester_id)
        .set('site_id',this.site_id)
        .set('user_id',this.user_id)
        .set('project_id',project_id)
        .set('job_id',job_id)
        .set('is_active',true)
 
        var apiMethod = "ProjectItemInfo/" + "getall";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url,{params: params});
  }

}
