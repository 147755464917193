<form [formGroup]="pItemForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Item</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="item_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Item Code</mat-label>
                <input matInput type="text" id="txtItemCode" class="matInputCls toUpperCase" name="text" formControlName="item_code"
                    maxlength="20" appOnlyAlphanumeric>
                <mat-error *ngIf="pItemForm.controls['item_code'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Item Title</mat-label>
                <input matInput type="text" id="txtItemTitle" class="matInputCls" name="text"
                    formControlName="item_title" maxlength="250">
                <mat-error *ngIf="pItemForm.controls['item_title'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Item Narrative</mat-label>
                <input matInput type="text" id="txtItemNarrative" class="matInputCls" name="text"
                    formControlName="item_narrative" maxlength="500">
                <mat-error *ngIf="pItemForm.controls['item_narrative'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">No. of Drawings</mat-label>
                <input matInput type="number" id="txtTotalDrawings" class="matInputCls" name="text"
                    formControlName="total_drawings">
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">No. of Welds</mat-label>
                <input matInput type="number" id="txtTotalWelds" class="matInputCls" name="text"
                    formControlName="total_welds">
            </mat-form-field>
        </div>
        <div class="item-container">
            <mat-checkbox formControlName="freeze" class="matInputCls" id="chkIsActive">
                Freeze
            </mat-checkbox>
            <mat-checkbox formControlName="is_active" class="matInputCls" id="chkIsActive">
                Is Active
            </mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="saveItemInfo()">Save</button>
        <!-- <button mat-raised-button (click)="closeDialog()">Close</button> -->
    </mat-dialog-actions>
</form>