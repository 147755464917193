import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../core/material-module';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { utility } from '../../../util';
import { groupModel } from '../../../models/groupModel';
import { UserService } from '../../../APIServices/UserService';
import { AccessService } from '../../../APIServices/AccessService';

@Component({
  selector: 'app-user-group-map',
  standalone: true,
  imports: [MaterialModule, FormsModule],
  templateUrl: './user-group-map.component.html',
  styleUrl: './user-group-map.component.scss'
})
export class UserGroupMapComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<UserGroupMapComponent>,
    private accessService: AccessService, private router: Router,
    private userService: UserService) { }
  selectedInfo: any;
  async ngOnInit() {
    await this.userService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);
    this.populateGroup();
  }
  selectedList: any[] = [];
  dModelList: any[] = [];

  closeDialog(): void {
    this.dialogRef.close();
  }
  async MapUserToGroup() {
    var uInfo = this.selectedInfo;
    if (uInfo == null) {
      alert("Please select user");
      return;
    }
    if (this.selectedList == null) {
      alert("Please select group");
      return;
    }
    var uArr: any = [];
    for (let i = 0; i < uInfo.length; i++) {
      uArr.push({
        user_id: uInfo[i].user_id,
        site_id: uInfo[i].site_id
      });
    }
    var gArr: any = [];
    for (let i = 0; i < this.selectedList.length; i++) {
      gArr.push({
        group_id: this.selectedList[i],
      });
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var userGroup = {
      userList: uArr,
      groupList: gArr,
      requester_id: loggedInUser.logon_id,
    };

    //Call API to update mapping
    (await this.userService.mapUserToGroup(userGroup)).subscribe((apiResponse: any) => {
      console.log(apiResponse);
      utility.responseAlert(apiResponse);
      //this.Index();
    });

  }
  async populateGroup() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var site_id = this.selectedInfo[0].site_id;
    (await this.accessService.getAllGroupList(loggedInUser.logon_id, site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);

      if (site_id == 1) {
        this.dModelList = this.dModelList.filter((v) => {
          return ((String(v.group_code)?.includes('SuperAdmin')));
        });
      }
      else {
        if (loggedInUser.site_id == 1) {
          this.dModelList = this.dModelList.filter((v) => {
            return ((String(v.site_id)?.includes(site_id)) && (v.is_admin == true));
          });
        }
        else {
          this.dModelList = this.dModelList.filter((v) => {
            return ((String(v.site_id)?.includes(site_id)));
          });
        }
      }
      if (this.selectedInfo != null && this.selectedInfo.length == 1) {
        var splitString = this.selectedInfo[0].merged_group_id.split(',');
        this.selectedList = splitString.map(Number);
      }
    });
  }
}
