import { Component, NgModule, OnInit } from '@angular/core';
import { UserService } from '../../../APIServices/UserService';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../util';
import { userModel } from '../../../models/userModel';
import { siteModel } from '../../../models/siteModel';
import { SiteService } from '../../../APIServices/SiteService';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatIntlTelInputComponent
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})

export class UserDetailsComponent implements OnInit {
  queryParams: any;
  disableLogon: boolean = false;
  sModelList: any[] = [];
  cModelList: any[] = [];
  selectedList: string[] = [];
  isSiteDisable: boolean = false;
  activeStatus: any = utility.activeStatus;
  selectedInfo: any;
  allSiteInfoList: any[] = [];

  uForm: FormGroup;
  displayedColumns: any;
  isSaveEnable: any = true;

  constructor(private route: ActivatedRoute, private userService: UserService
    , private siteService: SiteService, private router: Router) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    await this.userService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    await this.index();
    await this.getCountryCode();
    await this.populateSite();
    await this.fetchAllSiteInfo();
  }

  async fetchAllSiteInfo() {
    let allSiteInfo = this.selectedInfo.all_site_info;
    this.displayedColumns = ['site_code', 'site_description'];
    this.allSiteInfoList = allSiteInfo
  }

  async index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.isSaveEnable = this.selectedInfo?.can_edit ? this.selectedInfo?.can_edit : true;
    console.log('this.isSaveEnable', this.isSaveEnable);
    if(this.selectedInfo!=null){
      this.setFieldStatus(loggedInUser);
    }

    await this.initializeForm(this.selectedInfo);

    if (this.selectedInfo == null || this.selectedInfo == undefined
      || this.selectedInfo == '') {

      this.queryParams = this.route.snapshot.queryParams['user_id'];
      if (this.queryParams != undefined) {
        (await this.userService.getSelectedUser(loggedInUser.logon_id, this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          this.userService.setSelectedInfo(this.selectedInfo);
          this.setFieldStatus(loggedInUser);
          this.initializeForm(this.selectedInfo);
          this.fetchAllSiteInfo();
        });
      }
    }
  }
  setFieldStatus(loggedInUser) {
    if (this.selectedInfo.user_id == null || this.selectedInfo.user_id == 0
      || this.selectedInfo.user_id == undefined) {
      this.disableLogon = false;
    } else {
      this.disableLogon = true;
    }
    if (loggedInUser.site_id == 1) {
      this.isSiteDisable = false;
    }
    else {
      this.isSiteDisable = true;
    }
  }
  async initializeForm(uInfo: any) {
    if (uInfo != null) {
      this.uForm = new FormGroup({
        user_id: new FormControl(uInfo?.user_id),
        logon_id: new FormControl({ value: uInfo?.logon_id, disabled: this.disableLogon }
          , [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
          Validators.required]),
        full_name: new FormControl(uInfo?.full_name, [Validators.required]),
        short_name: new FormControl(uInfo?.short_name),
        user_email: new FormControl(uInfo?.user_email,
          [
            Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            Validators.required
          ]),
        job_function: new FormControl(uInfo?.job_function),
        site_id: new FormControl({ value: uInfo?.site_id, disabled: this.isSiteDisable }, [Validators.required]),
        address: new FormControl(uInfo?.address),
        country: new FormControl(uInfo?.country),
        zipcode: new FormControl(uInfo?.zipcode),
        country_code: new FormControl(uInfo?.country_code),
        telephone: new FormControl(uInfo?.telephone
        ),
        fax: new FormControl(uInfo?.fax,
          [Validators.pattern("^[0-9]*$")]
        ),
        is_active: new FormControl({ value: uInfo?.is_active, disabled: uInfo?.is_active }),
        merged_group_id: new FormControl(uInfo?.merged_group_id),
        requester_id: new FormControl('')
      });
    }
    else {
      this.uForm = new FormGroup({
        user_id: new FormControl(''),
        logon_id: new FormControl({ value: '', disabled: this.disableLogon }
          , [Validators.required]),
        full_name: new FormControl('', [Validators.required]),
        short_name: new FormControl(''),
        user_email: new FormControl('',
          [
            Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            Validators.required
          ]),
        job_function: new FormControl(''),
        site_id: new FormControl({ value: '', disabled: this.isSiteDisable }, [Validators.required]),
        address: new FormControl(''),
        country: new FormControl(''),
        zipcode: new FormControl(''),
        country_code: new FormControl(''),
        telephone: new FormControl(''
        ),
        fax: new FormControl('',
          [Validators.pattern("^[0-9]*$")]
        ),
        is_active: new FormControl({ value: true, disabled: true }),
        merged_group_id: new FormControl(''),
        requester_id: new FormControl('')
      });
    }
  }
  async save() {
    this.uForm.markAllAsTouched();
    const isFormValid = this.uForm.valid;
    if (!isFormValid) {
      return;
    }

    if (this.uForm.value['user_id'] == null) {
      this.createUser();
    }
    else {
      this.updateUser();
    }
  }
  async createUser() {

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.uForm.value['requester_id'] = loggedInUser.logon_id;

    (await this.userService.createUser(this.uForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateUser() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.uForm.value['requester_id'] = loggedInUser.logon_id;
    console.log(this.uForm.getRawValue());
    (await this.userService.updateUser(this.uForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  backtoList() {
    this.router.navigate(['./userlist']);
  }
  async populateSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getActiveSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.sModelList = utility.extractJsonData(apiResponse, 0);
      // if (this.userService.selectedUserInfo.logon_id != undefined) 
      //   this.selectedUserInfo.site_id = this.userService.selectedUserInfo.site_id;
    });
  }
  async getCountryCode(){
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.userService.getCountryCode(loggedInUser.logon_id)).subscribe((apiResponse: any) => {
      this.cModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
}
