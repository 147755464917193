import { Component } from '@angular/core';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { MaterialModule } from '../../../../../core/material-module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { Router } from '@angular/router';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { ProcessGroupService } from '../../../../../APIServices/master/weld/ProcessGroupService';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { utility } from '../../../../../util';

@Component({
  selector: 'app-process-group-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, ToolbarButtonsComponent],
  templateUrl: './process-group-list.component.html',
  styleUrl: './process-group-list.component.scss'
})
export class ProcessGroupListComponent {

  dModelList: any;
  title: string = 'Process Group';
  selectedInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Process-Group', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Process-Group', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router, 
    private processGroupService : ProcessGroupService) {
        
  }

  async ngOnInit(){ 
    await this.Index()
  }

  public async Index() {
  
    (await this.processGroupService.getAll()).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.processGroupService.setSelectedInfo(this.selectedInfo);
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./processgroupdetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.process_group_id;

    let params = {
      process_group_id: selectedID
    };
    this.router.navigate(['./processgroupdetails'], { queryParams: params });
    var uModel;
    // if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);

    //   var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //   uModel.site_id = loggedInUser.site_id;
    //   uModel.site_code = loggedInUser.site_code;
    //   uModel.site_description = loggedInUser.site_description;
    // }
    // else {
    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);
    // }
    
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);      
      this.processGroupService.setSelectedInfo(uModel);
      //this.router.navigate(['./processgroupdetails'], { queryParams: params });   
      e.event?.preventDefault();
  };

  RouteToCreate(){
    this.processGroupService.setSelectedInfo(null);
    this.router.navigate(['./processgroupdetails']);
  }

  async DeActivate(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Process Group?")) {
      return;
    }

    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //var empTypeList = this.selectedInfo;
    let params = {
      process_group_list: this.selectedInfo
    };

    // var userInfoDelete = {
    //   userList: this.selectedInfo//,
    //   //requester_id: loggedInUser.logon_id
    // };

    (await this.processGroupService.Delete(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
}
