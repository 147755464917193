<form [formGroup]="pJobForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Add/Edit Job</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="job_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Job Code</mat-label>
                <input matInput type="text" id="txtJobCode" class="matInputCls toUpperCase" name="text" formControlName="job_code"
                    maxlength="120" appOnlyAlphanumeric>
                <mat-error *ngIf="pJobForm.controls['job_code'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Job Title</mat-label>
                <input matInput type="text" id="txtJobTitle" class="matInputCls" name="text" formControlName="job_title"
                    maxlength="120">
                <mat-error *ngIf="pJobForm.controls['job_title'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container">
            <mat-checkbox formControlName="is_active" class="matInputCls" id="chkIsActive">
                Is Active
            </mat-checkbox>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="saveJobInfo()">Save</button>
        <!-- <button mat-raised-button (click)="closeDialog()">Close</button> -->
    </mat-dialog-actions>
</form>