import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../util';
import { LookupService } from '../../../APIServices/LookupService';
import { WeldService } from '../../../APIServices/WeldService';
import { FileUploadComponent } from '../../../home/file-upload/file-upload.component';
import { OnlyAlphanumericDirective } from '../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-resource-details',
  standalone: true,
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    FileUploadComponent,
    OnlyAlphanumericDirective
  ],
  templateUrl: './resource-details.component.html',
  styleUrl: './resource-details.component.scss'
})
export class ResourceDetailsComponent implements OnInit{
  resourceForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  resourceOptionList: any[] = [];
  typeCraftOptionList: any[] = [];
  resStatusOptionList: any[] = [];
  empTypeOptionList: any[] = [];
  empLocationOptionList: any[] = [];
  loggedInUser: any;
  selectedResType: null;
  imgaePreview: any;
  binaryFile: Blob | null = null;
  imageData: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private weldService: WeldService
  ) {
      this.initializeForm(null);
  }
  
  ngOnInit() {
    this.weldService.getSelectedInfo.subscribe(x => {
      if(utility.isObject(x)) {
        this.selectedInfo = x;
      } else {
        this.selectedResType = x;
      }
    });
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.index();
    this.populateResourceOptionList();
    this.populateEmpTypeOptionList();
    this.populateEmpLocationOptionList();
    
  }

  async index() {
    this.initializeForm(this.selectedInfo);
    if (this.selectedInfo == null || this.selectedInfo == undefined || this.selectedInfo == '') {
          this.queryParams = this.route.snapshot.queryParams['resource_id'];
          if (this.queryParams != undefined) {
            (await this.weldService.getSelectedResource(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              this.weldService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
    } else {
      this.fetchDependentFieldsValue(this.selectedInfo.res_type_id);
    }
  }

  async populateResourceOptionList() {
    (await this.lookupService.getResourceTypeList()).subscribe((apiResponse: any) => {
      this.resourceOptionList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async fetchDependentFieldsValue(value:any) {
      let selectedResourceType = value;
      if (selectedResourceType) {
        (await this.lookupService.getResStatusList(selectedResourceType)).subscribe((apiResponse: any) => {
          this.resStatusOptionList = utility.extractJsonData(apiResponse, 0);
        });
        (await this.lookupService.getResTypeCraftList(selectedResourceType)).subscribe((apiResponse: any) => {
          this.typeCraftOptionList = utility.extractJsonData(apiResponse, 0);
        });
      }
  }

  /* async populateTypeCraftOptionList() {
    (await this.lookupService.getResTypeCraftList()).subscribe((apiResponse: any) => {
      this.typeCraftOptionList = utility.extractJsonData(apiResponse, 0);
    });
  } */

  /* async populateResStatusOptionList() {
    (await this.lookupService.getResStatusList()).subscribe((apiResponse: any) => {
      this.resStatusOptionList = utility.extractJsonData(apiResponse, 0);
    });
  } */

  async populateEmpTypeOptionList() {
    (await this.lookupService.getEmpTypeList(this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.empTypeOptionList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async populateEmpLocationOptionList() {
    (await this.lookupService.getEmpLocationList(this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.empLocationOptionList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async initializeForm(uInfo: any) {
    if (uInfo != null) {
      this.resourceForm = new FormGroup({
        resource_id: new FormControl(uInfo?.resource_id),
        resource_code: new FormControl({ value: uInfo?.resource_code, disabled: true }, [Validators.required]),
        name: new FormControl(uInfo?.name, [Validators.required]),
        res_status_id: new FormControl(uInfo?.res_status_id),
        res_type_id: new FormControl({ value: uInfo?.res_type_id, disabled: true }, [Validators.required]),
        emp_type_id: new FormControl(uInfo?.emp_type_id, [Validators.required]),
        location_id: new FormControl(uInfo?.location_id, [Validators.required]),
        type_craft_id: new FormControl(uInfo?.type_craft_id),
        start_date: new FormControl(uInfo?.start_date, [Validators.required]),
        end_date: new FormControl(uInfo?.end_date),
        badge_no: new FormControl(uInfo?.badge_no, [Validators.required]),
        sap_id: new FormControl(uInfo?.sap_id, [Validators.required]),
        site_id: new FormControl(uInfo?.site_id),
        is_active: new FormControl({ value: uInfo?.is_active, disabled: uInfo?.is_active }),
        requester_id: new FormControl(''),
        res_image: new FormControl(null)

      }, {validators: this.dateRangeValidation});
      this.fetchDependentFieldsValue(uInfo?.res_type_id);

      const savedImage = uInfo?.res_image;
      if (savedImage) {
        this.resourceForm.patchValue({
          res_image: savedImage
        });
        this.imgaePreview = savedImage;
      }
    }
    else {
      this.resourceForm = new FormGroup({
        resource_id: new FormControl(null),
        resource_code: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        res_status_id: new FormControl(null),
        res_type_id: new FormControl({value: this.selectedResType, disabled: true}, [Validators.required]),
        emp_type_id: new FormControl('', [Validators.required]),
        location_id: new FormControl('', [Validators.required]),
        type_craft_id: new FormControl(null),
        start_date: new FormControl(null, [Validators.required]),
        end_date: new FormControl(null),
        badge_no: new FormControl('', [Validators.required]),
        sap_id: new FormControl('', [Validators.required]),
        site_id: new FormControl(''),
        is_active: new FormControl({ value: true, disabled: true }),
        requester_id: new FormControl(''),
        res_image: new FormControl(null)
      }, {validators: this.dateRangeValidation});
      this.fetchDependentFieldsValue(this.selectedResType);
    }
  }

  dateRangeValidation: ValidatorFn = (control: AbstractControl) => {
   // console.log('in date range validator fun');
    const form = control as FormGroup;
    const sDate = form.get('start_date')?.value;
    const eDate = form.get('end_date')?.value;
    const stDateControl = form.get('start_date');
    if (sDate && eDate && new Date(sDate) > new Date(eDate)) {
     // console.log('invalid date');
      stDateControl?.setErrors({dateInvalid: true});
      return {
        dateInvalid: true 
      };
    }
    return null;;
  }

  save() {
    this.resourceForm.markAllAsTouched();
    const isFormValid = this.resourceForm.valid;
    if (!isFormValid) {
      return;
    }
    if(this.imageData) {
      const formData = new FormData();
      formData.append('res_image', this.imageData);
    }
    if (this.resourceForm.value['resource_id'] === "" 
        || this.resourceForm.value['resource_id'] === null 
        || this.resourceForm.value['resource_id'] === undefined) {
          this.createUser();
    }
    else {
      this.updateUser();
    }
  }

  async createUser() {
    this.updateFormControls();
    (await this.weldService.createResource(this.resourceForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateUser() {
    this.updateFormControls();
   // console.log(this.resourceForm.getRawValue());
    (await this.weldService.updateResource(this.resourceForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  updateFormControls() {
    this.resourceForm.controls['requester_id'].setValue(this.loggedInUser.logon_id);
    this.resourceForm.controls['site_id'].setValue(this.loggedInUser.site_id);
   // this.resourceForm.controls['is_active'].setValue(this.loggedInUser.is_active);
  }

  backtoList() {
    this.router.navigate(['./resourcelist']);
  }

  onImageChange(base64Image: string | null):void {
    this.imageData = base64Image;
  }

/*   onFileDataReceived(binaryData: Blob): void {
    this.binaryFile = binaryData;
  } */


}
