<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="cls_drpdwn flex-container">
    <div>
        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
            <mat-label>Process Group</mat-label>
            <mat-select [(ngModel)]="selectedProcessGroup" (selectionChange)="applyFilter()">
                <ng-container>
                    @for (item of lookupProcessGroupList; track item) {
                    <mat-option [value]="item.process_group_id">{{item.process_group_code}}</mat-option>
                    }
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>   
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="dModelFilterList" keyExpr="process_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" 
        [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">

        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Process" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="process_id" [width]="100" caption="Process ID" [visible]="false"></dxi-column>
        <dxi-column dataField="process_code" [width]="100" caption="Code" sortOrder="asc"></dxi-column>
        <dxi-column dataField="process_title" [width]="200" caption="Description"></dxi-column>
        <dxi-column dataField="disc_title" [width]="200" caption="Discipline"></dxi-column>
        <dxi-column dataField="weld_metal_deposit" [width]="150" caption="Weld Metal Deposit"></dxi-column>
        <dxi-column dataField="process_group_code" [width]="150" caption="Process Group"></dxi-column>
        <!-- <dxi-column dataField="time_zone" [width]="200" caption="Time Zone"></dxi-column> -->
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>