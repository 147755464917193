import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WeldMasterService } from '../../../../../APIServices/master/weld/WeldMasterService';
import { utility } from '../../../../../util';
import { CommonModule } from '@angular/common';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-weld-masters-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './weld-masters-detail.component.html',
  styleUrl: './weld-masters-detail.component.scss'
})
export class WeldMastersDetailComponent implements OnInit {
  wmForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  masterTypeList: any = []
  selectedMasterType: any;


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: WeldMasterService) {

      this.initializeForm(null)
  }

  async ngOnInit() {      
    this.populateMasterTypeList();
  }

  async populateMasterTypeList(){
    (await this.service.getAllMasterType()).subscribe((apiResponse: any) => {
      this.masterTypeList = utility.extractJsonData(apiResponse, 0);  
      
      this.Index();
    });
  }

  async Index(){
      await this.service.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);     
      if (this.selectedInfo == null) {
          this.queryParams = this.activatedRoute.snapshot.queryParams['master_id'];

          if (this.queryParams != undefined) {
          this.bEditMode = true;

            (await this.service.getWeldMastersDetail(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              
              this.service.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
          else {
            this.service.getSelectedMasterTypeInfo$.subscribe( (y) =>{
              this.selectedMasterType = y??1;
              this.initializeForm(null);
            });
            this.bEditMode = false;        
          }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
      }
  }

  initializeForm(wmInfo: any){
    if (wmInfo != null){        
      this.wmForm = new FormGroup(
        {
          master_id: new FormControl(wmInfo?.master_id),
          master_type_id: new FormControl({value: wmInfo?.master_type_id, disabled:true}, [Validators.required]),
          code: new FormControl({ value: wmInfo?.code , disabled: this.bEditMode }, [Validators.required]),
          description: new FormControl(wmInfo?.description, [Validators.required]),    
          is_active: new FormControl({value: wmInfo?.is_active , disabled: wmInfo?.is_active })
        }
      )      
    }
    else{
          this.wmForm = new FormGroup(
          {
            master_id: new FormControl(null,),
            master_type_id: new FormControl({value: this.selectedMasterType , disabled:true }, [Validators.required]),
            code: new FormControl({value: '', disabled: this.bEditMode }, [Validators.required]),
            description: new FormControl({value: '', disabled: false }, [Validators.required]),           
            is_active: new FormControl({value: true, disabled: true })
          }
        )
    }
  }
  
  

  backtoList(){
    this.router.navigate(['./weldmasterslist']);
  }

  save(){
    this.wmForm.markAllAsTouched();
    const isFormValid = this.wmForm.valid;
    if (!isFormValid) {
      return;
    }
  
    if (this.wmForm.value['master_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }       
  }

  async createItem() {   
    (await this.service.CreateWeldMaster(this.wmForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList();
      };
      // if(apiResponse != null && apiResponse.response_error_id == "0"){
      //   this.backtoList()
      // }
    });    
  }

  async updateItem() {
    (await this.service.UpdateWeldMaster(this.wmForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList()
      };      
    });
  }

}
