import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { utility } from "../../util";

@Injectable({
    providedIn: 'root'
})
export class ProjectDisciplineService {
    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();
    
    constructor(private httpClient: HttpClient) {
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

    public async getAllProjectDisciplineList(requester_id: any, site_id: any, project_id: any, item_id: any){
        var apiMethod = "ProjectDiscipline/GetAll";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&project_id=" + project_id + "&item_id=" + item_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url).pipe(
            catchError(this.errorHandler)
        );
    }
    
    public async getSelectedProjectDiscipline(requester_id: any, project_id: any, site_id: any, item_id: any, discipline_id: any) {
        var apiMethod = "ProjectDiscipline/detail";
        var param = "?requester_id=" + requester_id + "&project_id=" + project_id + "&site_id=" + site_id + "&item_id=" + item_id + "&dp_id" + discipline_id;
        var url = (this.apiBaseUrl + apiMethod) + param;
        return this.httpClient.get(url).pipe(
            catchError(this.errorHandler)
        );
    }
    public async createProjectDiscipline(post: any) {
        var apiMethod = "ProjectDiscipline/Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    public async updateProjectDiscipline(put: any) {
        var apiMethod = "ProjectDiscipline/Update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async deleteProjectDiscipline(del: any) {
        var apiMethod = "ProjectDiscipline/Delete";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }
    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}