<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<!-- <app-toolbar-buttons [title]="title"></app-toolbar-buttons> -->
<div class="display-flex">
    <div class="flex-grow-2 ml-1em">
        <dx-tree-list id="treeProjectList" [dataSource]="pModelList" keyExpr="project_id"
            parentIdExpr="parent_project_id" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="true"
            class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)"
            (onContentReady)="onProjectContentReady($event)">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="project_id" [width]="100" caption="Project ID" [visible]="false"></dxi-column>
            <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
            <dxi-column dataField="project_code" [width]="125" caption="Project Code"></dxi-column>
            <dxi-column dataField="project_title" [width]="175" caption="Project Title"></dxi-column>
            <dxi-column dataField="parent_count" [width]="70" caption="Parent"></dxi-column>
            <dxi-column dataField="is_active" [width]="70" caption="Is Active" [visible]="false"></dxi-column>

            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-paging [pageSize]="10" [pageIndex]="0">
            </dxo-paging>
        </dx-tree-list>
    </div>
    <div class="pl-pr">
        <div class="example-action-buttons">
            <button mat-button class="action-btn" (click)="accordion().openAll()">Expand All</button>
            <button mat-button class="action-btn" (click)="accordion().closeAll()">Collapse All</button>
        </div>
        <mat-accordion class="example-headers-align" multi="true">
            <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)">
                <mat-expansion-panel-header>
                    <mat-panel-title> Job Information </mat-panel-title>
                    <mat-divider></mat-divider>
                    <!-- <mat-panel-description>
                  Type your name and age
                  <mat-icon>account_circle</mat-icon>
                </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!-- Job accordian -->
                <div>
                    <dx-data-grid width="90%" #JobDataGrid id="gridSelectedProjectJob" [dataSource]="pJobModelList"
                        keyExpr="job_id" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
                        [rowAlternationEnabled]="true" (onSelectionChanged)="onJobSelectionChanged($event)"
                        (onContentReady)="onJobContentReadyHandler($event)">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Job" icon="edit" [visible]=true [disabled]=false
                                [onClick]="RouteToJobDetails"></dxi-button>
                        </dxi-column>
                        <dxi-column dataField="job_id" [width]="100" caption="Job ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="project_id" [width]="100" caption="Project ID"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="job_code" [width]="100" caption="Job Code"></dxi-column>
                        <dxi-column dataField="job_title" caption="Job Title"></dxi-column>
                        <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column>
                    </dx-data-grid>
                </div>

                <!-- #docregion action-bar -->
                <!--  <mat-action-row>
                <app-toolbar-buttons [items]="toolbarItems"></app-toolbar-buttons>
              </mat-action-row> -->
                <!-- #enddocregion action-bar -->
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> Item Information </mat-panel-title>
                    <!-- <mat-panel-description>
                  Type the country name
                  <mat-icon>map</mat-icon>
                </mat-panel-description> -->
                </mat-expansion-panel-header>

                <div>
                    <dx-data-grid width="90%" id="gridSelectedProjectItem" [dataSource]="pItemModelList"
                        keyExpr="item_id" [showBorders]="true" [showColumnLines]="true" [rowAlternationEnabled]="true"
                        [showRowLines]="true" (onSelectionChanged)="onItemSelectionChanged($event)"
                        (onContentReady)="onItemContentReadyHandler($event)">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Item" icon="edit" [visible]=true [disabled]=false
                                [onClick]="RouteToItemDetails"></dxi-button>
                        </dxi-column>
                        <dxi-column dataField="item_id" caption="Item ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="job_id" caption="Job ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_id" caption="Site ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="project_id" caption="Project ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="item_code"  caption="Item Code"></dxi-column>
                        <dxi-column dataField="item_title"  caption="Item Title"></dxi-column>
                        <dxi-column dataField="item_narrative" caption="Item Narrative"></dxi-column>
                        <dxi-column dataField="total_drawings" [width]="100" caption="No. of Drawings"></dxi-column>
                        <dxi-column dataField="total_welds" [width]="100" caption="No. of Welds"></dxi-column>
                        <dxi-column dataField="freeze" [width]="100" caption="Freeze"></dxi-column>
                        <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column>
                    </dx-data-grid>
                </div>

                <!-- <mat-action-row>
                <app-toolbar-buttons [items]="itemsinfobtn"></app-toolbar-buttons>
              </mat-action-row> -->
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> Discipline Information </mat-panel-title>
                </mat-expansion-panel-header>

                <div>
                    <dx-data-grid width="90%" id="gridSelectedProjectDiscipline" [dataSource]="pDisciplineModelList"
                        keyExpr="pd_id" [showBorders]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                        (onSelectionChanged)="onDiscSelectionChanged($event)"
                        (onContentReady)="onDisciplineContentReadyHandler($event)" [showRowLines]="true">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected Item" icon="edit" [visible]=true [disabled]=false
                                [onClick]="RouteToDisciplineDetails"></dxi-button>
                        </dxi-column>
                        <dxi-column dataField="disc_title" caption="Discipline"></dxi-column>
                        <dxi-column dataField="is_active" [width]="100" caption="Is Active?"></dxi-column>
                    </dx-data-grid>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> Test Information </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="testDropdwn">
                    <mat-form-field appearance="outline" class="small clsFloatingField">
                        <mat-label class="matInputCls">Category</mat-label>
                        <mat-select formControlName="category_id" class="matInputCls" [value]="defaultTestCategory"
                            (selectionChange)="populateTestsByTestsCategory($event.value)">
                            @for (category of lookupTestCategory; track category.category_id) {
                            <mat-option class="matInputCls"
                                [value]="category.category_id">{{category.category_description}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>

                <dx-data-grid width="90%" #TestsDataGrid id="gridSelectedProjectJob" [dataSource]="pTestsModelList"
                    keyExpr="ti_id" [showBorders]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                    [showRowLines]="true" (onSelectionChanged)="onTestsSelectionChanged($event)"
                    (onContentReady)="onTestsContentReadyHandler($event)">
                    <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
                    <dxi-column type="buttons" [width]="50">
                        <dxi-button hint="Edit selected Tests" icon="edit" [visible]=true [disabled]=false
                            [onClick]="RouteToTestsDetails"></dxi-button>
                    </dxi-column>
                    <dxi-column dataField="test_code" [width]="100" caption="Tests Type"></dxi-column>
                    <dxi-column dataField="test_procedure" caption="Applicable Procedure"></dxi-column>
                    <dxi-column dataField="acceptance_criteria" caption="Acceptance Criteria"></dxi-column>
                    <dxi-column dataField="report_counter" [width]="120" caption="Report Counter"></dxi-column>
                </dx-data-grid>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>