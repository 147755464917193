<form [formGroup]="aForm" class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Assign Access</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <input type="hidden" formControlName="access_id" />
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Module</mat-label>
                <mat-select formControlName="module_id" (selectionChange)="fetchParentNode($event.value)">
                    <ng-container>
                        @for (gItem of dModuleList; track gItem) {
                        <mat-option [value]="gItem.module_id">{{gItem.module_code}} -
                            {{gItem.module_name}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="aForm.controls['module_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Parent Menu</mat-label>
                <mat-select formControlName="parent_id" (selectionChange)="fetchLeafNode($event.value)">
                    <ng-container>
                        @for (gItem of dMenuListParent; track gItem) {
                        <mat-option [value]="gItem.menu_id">{{gItem.menu_name}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="aForm.controls['parent_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>

        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label>Select Page</mat-label>
                <mat-select formControlName="leaf_menu_id">
                    <ng-container>
                        @for (gItem of dMenuListLeaf; track gItem) {
                        <mat-option [value]="gItem.menu_id">{{gItem.menu_name}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="aForm.controls['leaf_menu_id'].errors?.['required']">
                    This is required!
                </mat-error>
            </mat-form-field>
        </div>
        <div class="item-container">
            <mat-checkbox formControlName="can_view" class="matInputCls" id="chkView">
                View
            </mat-checkbox>
            <mat-checkbox formControlName="can_add" class="matInputCls" id="chkAdd">
                Add
            </mat-checkbox>
            <mat-checkbox formControlName="can_edit" class="matInputCls" id="chkEdit">
                Edit
            </mat-checkbox>
            <mat-checkbox formControlName="can_delete" class="matInputCls" id="chkView">
                Delete
            </mat-checkbox>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="saveGroupAccess()" [disabled]="aForm.invalid">Save</button>
    </mat-dialog-actions>
</form>
