import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../core/material-module';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { utility } from '../../../util';
import { UserService } from '../../../APIServices/UserService';
import { SiteService } from '../../../APIServices/SiteService';
import { siteModel } from '../../../models/siteModel';

@Component({
  selector: 'app-user-site-map',
  standalone: true,
  imports: [MaterialModule, FormsModule],
  templateUrl: './user-site-map.component.html',
  styleUrl: './user-site-map.component.scss'
})
export class UserSiteMapComponent {
  siteLabel: any;
  isCurrentSite: boolean;
  isCurrentDisable: boolean;
  selectedList: string[] = [];
  dModelList: siteModel[] = [];
  selectedInfo: any;

  constructor(private dialogRef: MatDialogRef<UserSiteMapComponent>,
    private siteService: SiteService, private router: Router,
    private userService: UserService) { }

  async ngOnInit() {
    await this.userService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    await this.populateSite();

    if ((this.selectedInfo != null) && (this.selectedInfo[0].site_id == null
      || this.selectedInfo[0].site_id == undefined
      || this.selectedInfo[0].site_id == 0)) {
      this.siteLabel = "Select Base Site"
      this.isCurrentDisable = true;
      this.isCurrentSite = true;
    }
    else {
      this.siteLabel = "Select Site"
      this.isCurrentDisable = false;
      this.isCurrentSite = false;
    }
  }


  closeDialog(): void {
    this.dialogRef.close();
  }
  async MapUserToSite() {
    if (this.selectedInfo == null) {
      alert("Please select user");
      return;
    }
    if (this.selectedList == null) {
      alert("Please select site");
      return;
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var userSiteMapInfo = {
      site_id: this.selectedList,
      requester_id: loggedInUser.logon_id,
      is_current: this.isCurrentSite,
      userList: this.selectedInfo
    };
    console.log(userSiteMapInfo);
    //Call API to update mapping
    (await this.userService.mapUserToSite(userSiteMapInfo)).subscribe((apiResponse: any) => {
      console.log(apiResponse);
      utility.responseAlert(apiResponse);
    });

  }
  async populateSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getActiveSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
      if (this.selectedInfo != null && this.selectedInfo.length <= 1)
        this.selectedList = this.selectedInfo[0].site_id;
      this.isCurrentSite = this.selectedInfo[0].is_current;
    });
  }
}
