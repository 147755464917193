import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeTypeService } from '../../../../../APIServices/master/weld/employee-type.service';
import { utility } from '../../../../../util';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-employee-type-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './employee-type-detail.component.html',
  styleUrl: './employee-type-detail.component.scss'
})
export class EmployeeTypeDetailComponent implements OnInit {
  eForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  /**
   *
   */
  constructor(private router: Router,
              private route: ActivatedRoute,
              private employeeTypeService: EmployeeTypeService
  ) {   
    this.initializeForm(null); 
  }

  async ngOnInit() {
    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.employeeTypeService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['employee_type_id'];
      
      if (this.queryParams != undefined) {
        this.bEditMode = true;
        
        (await this.employeeTypeService.get(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          
          this.employeeTypeService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {        
          this.bEditMode = false;        
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
    
  }

  async initializeForm(eInfo: any) {

    if (eInfo != null){        
      this.eForm = new FormGroup(
        {
          employee_type_id: new FormControl(eInfo?.employee_type_id ),
          employee_type_code: new FormControl({ value: eInfo?.employee_type_code , disabled: this.bEditMode }, [Validators.required]),
          employee_type_title: new FormControl(eInfo?.employee_type_title, [Validators.required]),
          is_active: new FormControl({value: eInfo?.is_active, disabled: eInfo?.is_active})
        }
      )      
    }
    else{
      this.eForm = new FormGroup(
        {
          employee_type_id: new FormControl(null),
          employee_type_code: new FormControl({ value: '', disabled: this.bEditMode }, [Validators.required]),
          employee_type_title: new FormControl({ value: '', disabled: false }, [Validators.required]),
          is_active: new FormControl({ value: true, disabled: true })
        }
      )
    }

  }

  backtoList(){
    this.router.navigate(['./employeetypelist']); 
  }

  /**
   *
   */
  async save(){
    this.eForm.markAllAsTouched();
    const isFormValid = this.eForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.eForm.value['employee_type_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }
  }

  async createItem() {
    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.employeeTypeService.Post(this.eForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);

      if(apiResponse != null && apiResponse.response_error_id == "0"){
        this.backtoList()
      }
    });
  }
  async updateItem() {
    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.employeeTypeService.Put(this.eForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      
      if(apiResponse != null && apiResponse.response_error_id == "0"){
        this.backtoList()
      }
    });
  }
}
