<mat-toolbar fxLayout="row" class="mat-elevation-z3">
  @if (isLoggedIn()) {
  <div class="toggle">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>apps</mat-icon>
    </button>
  </div>
  }
  <span fxFlex><a class="logo ml-1P" href="{{baseUrl}}"><img src="./assets/img/logo.png"></a></span>

  <!-- <button mat-button fxHide.xs class="menu-forecolor nav-hyperlink-btn" routerLink="dashboard">Skip to main content</button> -->
  <!-- <mat-icon fxHide.lt-sm matTooltip="Skip to main content">fast_forward</mat-icon> -->
  @if(isLoggedIn() && getName()){
    <!-- <div class="wlcmMsg"> -->
      <span class="lbl_userName">Welcome {{getName()}} / </span>
      <span class="lbl_siteName">{{getSiteNameInfo()}}</span>
    <!-- </div> -->
    
  }
  @if (isLoggedIn()) {
    <button (click)="openHomeMenu()" fxHide.lt-sm mat-icon-button>
    <mat-icon class="material-symbols-outlined">home</mat-icon>
    <!-- <mat-menu #home="matMenu">
      <span mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom" (click)="openNotification()">Notification</span>
    </mat-menu> -->
  </button>
  }
  
  <button fxHide.lt-sm mat-icon-button [matMenuTriggerFor]="note1">
    <mat-icon class="material-symbols-outlined">notifications</mat-icon>
    <mat-menu #note1="matMenu">
      <span mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom" (click)="openNotification()">Notification</span>
    </mat-menu>
  </button>
  <button fxHide.lt-sm mat-icon-button [matMenuTriggerFor]="about1">
    <mat-icon>help</mat-icon>
    <mat-menu #about1="matMenu">
      <span mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom" (click)="openAbout()">About Application</span>
      <span mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom" (click)="openFAQ()">FAQ</span>
    </mat-menu>
  </button>

  @if(isLoggedIn()){
  <button mat-icon-button [matMenuTriggerFor]="profile2" class="mr-16">
    <img src="./assets/img/profile.png" id="menuButton" class="mat-button">
    <mat-menu #profile2="matMenu">
      <span mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom">{{getName()}}</span>
      <button mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom"
        (click)="logout()">Logout</button>
    </mat-menu>
  </button>
  }
  @else {
  <button mat-icon-button [matMenuTriggerFor]="profile2" class="mr-1P">
    <img src="./assets/img/profile.png" id="menuButton" class="mat-button">
    <mat-menu #profile2="matMenu">
      <button mat-menu-item class="mat-mdc-menu-item-custom mat-mdc-menu-item-text-custom" (click)="login()">Log
        In</button>
    </mat-menu>
  </button>
  }
  <button mat-icon-button [matMenuTriggerFor]="dropMenu" fxHide fxShow.lt-sm color="white">
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #dropMenu="matMenu">
  <div class="flex-container-column">
    <div>
      <!-- <mat-icon class="icon-color" matTooltip="Skip to main content">fast_forward</mat-icon> -->
      <mat-icon class="icon-color" matTooltip="Notifications" (click)="openNotification()">notifications</mat-icon>
      <mat-icon class="icon-color" matTooltip="About" (click)="openAbout()">info</mat-icon>
      <mat-icon class="icon-color" matTooltip="FAQ" (click)="openFAQ()">question_answer</mat-icon>
    </div>
    <!-- <mat-divider></mat-divider>
      <div>
        <button mat-button>
          **removed nav menu temporary from here 
        </button>
      </div> -->
    <!-- <button mat-button class="menu-forecolor nav-hyperlink-btn" routerLink="dashboard">Skip to main content</button> -->
  </div>
</mat-menu>

<div class="gpcm-container">
  <mat-sidenav-container class="gpcm-sidenav-container">
    <!-- remove : [style.marginTop.px]="mobileQuery.matches ? 56 : 0" for better mobile view -->
    <mat-sidenav class="sideNavFixedWidth" #snav [mode]="'over'" fixedTopGap="56">
      <app-custom-sidenav [menuItem]="authmenu" [snav]="snav" />
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-loader />
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>