import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { MaterialModule } from '../../../../../core/material-module';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { WeldMasterService } from '../../../../../APIServices/master/weld/WeldMasterService';
import { utility } from '../../../../../util';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-weld-masters-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, MaterialModule, FormsModule],
  templateUrl: './weld-masters-list.component.html',
  styleUrl: './weld-masters-list.component.scss'
})
export class WeldMastersListComponent implements OnInit {
  wmModelList: any;
  wmModelFilterList: any;
  title: string = 'Weld Masters';
  selectedInfo: any;
  lookupMaterTypeList: any = []
  selectedMasterType: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];


  /**
   *
   */
  constructor(private router: Router,
      private service: WeldMasterService) {    
  }

  async ngOnInit() {
    await this.populateLookups(); 
      
  }

  async populateLookups(){
    (await this.service.getAllMasterType()).subscribe((apiResponse: any) => {
      this.lookupMaterTypeList = utility.extractJsonData(apiResponse, 0);      

      if (this.lookupMaterTypeList != null && this.lookupMaterTypeList.length > 0){
        this.selectedMasterType = this.lookupMaterTypeList[0].master_type_id;
        this.Index();          
      };
    });
  }

  public async Index() {     
    ( this.service.getAll()).subscribe((apiResponse: any) => {
      this.wmModelList = utility.extractJsonData(apiResponse, 0);
      this.applyFilter();
    });
    
  }

  
  async applyFilter(){
    if(this.wmModelList != null && this.wmModelList != undefined && this.wmModelList.length > 0){
      this.wmModelFilterList = this.wmModelList.filter((v) => 
        v.master_type_id === (this.selectedMasterType == 0 ? null : this.selectedMasterType)
      );
    }
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.service.setSelectedInfo(this.selectedInfo);
  }

  RouteToCreate(){
    this.service.setSelectedInfo(null);
    this.service.setSelectedMasterTypeInfo(this.selectedMasterType);
    this.router.navigate(['./weldmastersdetails']);
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./weldmastersdetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.master_id;

    let params = {
      master_id: selectedID
    };
    this.router.navigate(['./weldmastersdetails'], { queryParams: params });
    var uModel;
    // if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);

    //   var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //   uModel.site_id = loggedInUser.site_id;
    //   uModel.site_code = loggedInUser.site_code;
    //   uModel.site_description = loggedInUser.site_description;
    // }
    // else {
    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);
    // }
    
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);      
      this.service.setSelectedInfo(uModel);
      //this.router.navigate(['./processgroupdetails'], { queryParams: params });   
      e.event?.preventDefault();
  }

  async DeActivate(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a record");
      return      
      };

      if (!confirm("Are you sure to Inactivate selected record(s)?")) {
        return;
      }

      let params = {
        weld_master_list: this.selectedInfo
      };

      (await this.service.Delete(params)).subscribe((apiResponse: any) => {
        utility.responseAlert(apiResponse);
        this.Index();
      });

    }




}
