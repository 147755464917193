import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { utility } from '../../../util';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from '../../../APIServices/LookupService';
import { CommonModule } from '@angular/common';
import { TestProcedureService } from '../../../APIServices/testacceptance/TestProcedureService';

@Component({
  selector: 'app-test-procedure-detail',
  standalone: true,
  imports: [MaterialModule,ReactiveFormsModule, CommonModule],
  templateUrl: './test-procedure-detail.component.html',
  styleUrl: './test-procedure-detail.component.scss'
})
export class TestProcedureDetailComponent implements OnInit {
  tForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;

  lookupDisciplineList: any[] = [];
  lookupTestTypeList: any[] = [];
  loggedInUser: any;

  /**
   *
   */
  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private lookupService: LookupService,
    private testProcedureService: TestProcedureService ) {  
    
      this.initializeForm(null);  
  }

  async ngOnInit(){
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.populateDisciplineList()
    this.populateTestTypeList();

    await this.Index();
  }

  async Index(){
    await this.testProcedureService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

      if (this.selectedInfo == null) {
          this.queryParams = this.activateRoute.snapshot.queryParams['ps_id'];

          if (this.queryParams != undefined) {
          this.bEditMode = true;

            (await this.testProcedureService.getTestProcedureDetail(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              
              this.testProcedureService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
          else {        
            this.bEditMode = false;        
          }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
      }
  }

  initializeForm(tInfo: any){
    if (tInfo != null){        
      this.tForm = new FormGroup(
        {
          ps_id: new FormControl(tInfo?.ps_id),
          job_code: new FormControl({ value: tInfo?.job_code , disabled: this.bEditMode }),
          item_code: new FormControl(tInfo?.item_code),
          disc_id: new FormControl(tInfo?.disc_id),
          test_id: new FormControl(tInfo?.test_id),
          base_procedure: new FormControl(tInfo?.base_procedure),          
          test_procedure: new FormControl(tInfo?.test_procedure),
          is_active: new FormControl({value: tInfo?.is_active , disabled: tInfo?.is_active })
        }
      )      
    }
    else{
      this.tForm = new FormGroup(  
        {
          ps_id: new FormControl(null),
          job_code: new FormControl(''),
          item_code: new FormControl(''),
          disc_id: new FormControl(null),
          test_id: new FormControl(null),
          base_procedure: new FormControl(''),  
          test_procedure: new FormControl(''),        
          is_active: new FormControl({ value: true, disabled: true })
        }
      )
    }
  }

  private async populateTestTypeList(){
    (await this.lookupService.getTestsTypes(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupTestTypeList = utility.extractJsonData(apiResponse, 0);
    });
  }

  private async populateDisciplineList(){
    (await this.lookupService.getDiscipline(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupDisciplineList = utility.extractJsonData(apiResponse, 0);
    });
  }

  backtoList(){
    this.router.navigate(['./testprocedurelist']);
  }

  save(){

  }

}
