import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { utility } from '../../../../../util';
import { LookupService } from '../../../../../APIServices/LookupService';
import { MaterialModule } from '../../../../../core/material-module';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialGradeService } from '../../../../../APIServices/master/weld/material-grade.service';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-material-grade-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './material-grade-detail.component.html',
  styleUrl: './material-grade-detail.component.scss'
})
export class MaterialGradeDetailComponent implements OnInit {
  mgForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  material_type_id: any;
  bEditMode: boolean = false;
  lookupMaterialTypeList: any[] = [];
  loggedInUser: any;

  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private lookupService: LookupService,
    private materialgradeService: MaterialGradeService) {

    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.populateMaterialTypeList();
    await this.Index();
  }

  async Index() {
    await this.materialgradeService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.activateRoute.snapshot.queryParams['material_grade_id'];
      this.material_type_id = this.activateRoute.snapshot.queryParams['material_type_id'];

      if (this.queryParams != undefined) {
        this.bEditMode = true;

        (await this.materialgradeService.getMaterialGradeDetails(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];

          this.materialgradeService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {
        this.bEditMode = false;
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
  }

  initializeForm(mgInfo: any) {
    if (mgInfo != null && (mgInfo?.material_grade_id != null || mgInfo?.material_grade_id != undefined)) {
      this.mgForm = new FormGroup(
        {
          material_grade_id: new FormControl(mgInfo?.material_grade_id),
          material_grade_code: new FormControl({ value: mgInfo?.material_grade_code , disabled: this.bEditMode }, [Validators.required]),
          material_grade_title: new FormControl(mgInfo?.material_grade_title, [Validators.required]),
          material_type_id: new FormControl({ value: mgInfo?.material_type_id, disabled: this.bEditMode }, [Validators.required]),
          is_active: new FormControl({ value: mgInfo?.is_active, disabled: mgInfo?.is_active })
        }
      )
    }
    else {
      this.mgForm = new FormGroup(
        {
          material_grade_id: new FormControl(null),
          material_grade_code: new FormControl({ value: '', disabled: false }, [Validators.required]),
          material_grade_title: new FormControl({ value: '', disabled: false }, [Validators.required]),
          material_type_id: new FormControl({ value: mgInfo?.material_type_id, disabled: true }, [Validators.required]),
          is_active: new FormControl({ value: true, disabled: true })
        }
      )
    }
  }

  private async populateMaterialTypeList() {
    (await this.lookupService.getMaterialTypeList(this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.lookupMaterialTypeList = utility.extractJsonData(apiResponse, 0);
    });
  }

  backtoList() {
    this.router.navigate(['./materialgradelist']);
  }

  save() {
    this.mgForm.markAllAsTouched();
    const isFormValid = this.mgForm.valid;
    if (!isFormValid) {
      return;
    }

    if (this.mgForm.value['material_grade_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }
  }

  async createItem() {
    (await this.materialgradeService.saveMaterialGrade(this.mgForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)) {
        this.backtoList()
      };
      // if(apiResponse != null && apiResponse.response_error_id == "0"){
      //   this.backtoList()
      // }
    });
  }

  async updateItem() {
    (await this.materialgradeService.updateMaterialGrade(this.mgForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)) {
        this.backtoList();
      };
    });
  }

}
