<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="dModelList" keyExpr="process_group_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" 
        [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">

        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Record" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>        
        <dxi-column dataField="process_group_id" [width]="100" caption="Location ID" [visible]="false"></dxi-column>
        <dxi-column dataField="process_group_code" [width]="100" caption="Code"></dxi-column>
        <dxi-column dataField="process_group_title" [width]="200" caption="Description"></dxi-column>        
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>