import { Injectable } from '@angular/core';
import { utility } from '../../util';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AcceptanceCriteriaService {

  requester_id: string;
  site_id: string;
  site_code : string;
  apiBaseUrl: string;

  apiEndPoint: string = "AcceptanceCriteriaInfo/"
  private environmentConfig = utility.getEnvironmentData();


  private selectedInfo = new BehaviorSubject<any>(null);
  getSelectedInfo$ = this.selectedInfo.asObservable();

  constructor(private httpClient: HttpClient,
    private router: Router) { 
    
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      this.requester_id = loggedInUser.logon_id
      this.site_id = loggedInUser.site_id
      this.apiBaseUrl = this.environmentConfig.weldApiBase; //environment.weldApiBase;
  }

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }

  setSelectedInfo(paramData: any) {   
    this.selectedInfo.next(paramData)
  }

  getAll(job_id: any, item_id: any){
    const params = new HttpParams()
      .set('requester_id',this.requester_id)
      .set('site_id',this.site_id)
      .set('job_id',job_id)
      .set('item_id',item_id) 

      var apiMethod = this.apiEndPoint + "GetAll";
      var url = (this.apiBaseUrl + apiMethod);
      return this.httpClient.get(url,{params: params});
  }
}
