import { Component } from '@angular/core';
import { LoadingService } from '../APIServices/loader.service';
import { delay } from 'rxjs';
import {MaterialModule} from './../core/material-module'

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  loading: boolean = false;
  constructor(
    private _loading: LoadingService
  ){ }
  
  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
