import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProcessGroupService } from '../../../../../APIServices/master/weld/ProcessGroupService';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../../util';
import { CommonModule } from '@angular/common';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-process-group-detail',
  standalone: true,
  imports: [MaterialModule,ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './process-group-detail.component.html',
  styleUrl: './process-group-detail.component.scss'
})
export class ProcessGroupDetailComponent implements OnInit {
  pgForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private processGroupService: ProcessGroupService) {

      this.initializeForm(null)
  }

  async ngOnInit() {  
    await this.Index();
  }

  async Index(){
      await this.processGroupService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

      if (this.selectedInfo == null) {
          this.queryParams = this.activatedRoute.snapshot.queryParams['process_group_id'];

          if (this.queryParams != undefined) {
          this.bEditMode = true;

            (await this.processGroupService.getProcessGroupDetail(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              
              this.processGroupService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
          else {        
            this.bEditMode = false;        
          }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
      }
  }

  initializeForm(pgInfo: any){
    if (pgInfo != null){        
      this.pgForm = new FormGroup(
        {
          process_group_id: new FormControl(pgInfo?.process_group_id),
          process_group_code: new FormControl({ value: pgInfo?.process_group_code , disabled: this.bEditMode }, [Validators.required]),
          process_group_title: new FormControl(pgInfo?.process_group_title, [Validators.required]),    
          is_active: new FormControl({value: pgInfo?.is_active , disabled: pgInfo?.is_active })
        }
      )      
    }
    else{
          this.pgForm = new FormGroup(
          {
            process_group_id: new FormControl(null,),
            process_group_code: new FormControl({value: '', disabled: this.bEditMode }, [Validators.required]),
            process_group_title: new FormControl({value: '', disabled: false }, [Validators.required]),           
            is_active: new FormControl({value: true, disabled: true })
          }
        )
    }
  }  

  backtoList(){
    this.router.navigate(['./processgrouplist']);
  }

  save(){
    this.pgForm.markAllAsTouched();
    const isFormValid = this.pgForm.valid;
    if (!isFormValid) {
      return;
    }
  
    if (this.pgForm.value['process_group_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }       
  }

  async createItem() {   
    (await this.processGroupService.CreateProcessGroup(this.pgForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList();
      };
      // if(apiResponse != null && apiResponse.response_error_id == "0"){
      //   this.backtoList()
      // }
    });    
  }

  async updateItem() {
    (await this.processGroupService.UpdateProcessGroup(this.pgForm.getRawValue())).subscribe((apiResponse: any) => {
      if (utility.responseAlert(apiResponse)){
        this.backtoList()
      };      
    });
  }
}
