import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { Router } from '@angular/router';
import { AcceptanceCriteriaService } from '../../../APIServices/testacceptance/AcceptanceCriteriaService';
import { utility } from '../../../util';
import { SearchProjectjobitemComponent } from "../../common/search-projectjobitem/search-projectjobitem.component";

@Component({
  selector: 'app-acceptance-criteria-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, SearchProjectjobitemComponent],
  templateUrl: './acceptance-criteria-list.component.html',
  styleUrl: './acceptance-criteria-list.component.scss'
})
export class AcceptanceCriteriaListComponent implements OnInit {
  aModelList: any;
  title: string = 'Acceptance Criteria Maintenance';
  selectedInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add AcceptanceCriteria', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate AcceptanceCriteria', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  /**
   *
   */
  constructor(private router: Router, 
    private acceptanceCriteriaService : AcceptanceCriteriaService) {    
    
  }

  async ngOnInit(){ 
    await this.Index()
  }

  public async Index() {
    
    // var job_id = 16;
    // var item_id = 19;    

    // (await this.acceptanceCriteriaService.getAll(job_id, item_id)).subscribe((apiResponse: any) => {
    //   this.aModelList = utility.extractJsonData(apiResponse, 0);
    // });
  }

  RouteToCreate(){
  }

  DeActivate(){
  }

  onSelectionChanged(event: any){
  }

  RouteToDetails(){

  }

  async onSearch(e: any){
    var project_id = e?.project_id;
    var job_id = e?.job_id;
    var item_id = e?.item_id;

    (await this.acceptanceCriteriaService.getAll(job_id, item_id)).subscribe((apiResponse: any) => {
      this.aModelList = utility.extractJsonData(apiResponse, 0);
    });

  }

  onResetSearch(e: any){
    this.aModelList = null;
  }
}
