import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// bootstrapApplication(AppComponent, appConfig)
//   .catch((err) => console.error(err));

fetch('./assets/environment-config.json')
  .then((resp) => resp.json())
  .then((config) => {
    bootstrapApplication(AppComponent, appConfig(config))
      .catch((err) => console.error(err));
  });



// fetch('./assets/environment-config.json')
// .then((resp) => resp.json())
// .then((config) => {
//   bootstrapApplication(AppComponent, appConfig(config));
// });