import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { utility } from "../util";
import { BehaviorSubject, catchError, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();

    public isNewAccess: any;
    constructor(private httpClient: HttpClient) {
        this.apiBaseUrl = this.environmentConfig.apiBase;
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    public async getAllGroupList(requester_id: any, site_id: any) {
        var apiMethod = "GroupInfo/GetAll";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&head_id=0" + "&is_active=true";
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getGroupAccessList(requester_id: any, site_id: any, group_id: any) {
        var apiMethod = "Authorization/GroupAccess";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&group_id=" + group_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getModuleList(requester_id: any, site_id: any) {
        var apiMethod = "Lookup/Module";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }
    public async getParentMenuList(requester_id: any, site_id: any, auth_module_id: any) {
        var apiMethod = "Lookup/ParentNode";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&module_id=" + auth_module_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }
    public async getLeafMenuList(requester_id: any, site_id: any, parent_id: any) {
        var apiMethod = "Lookup/LeafNode";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&parent_id=" + parent_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }
    public async saveGroupAccess(post: any) {
        var apiMethod = "Authorization/SaveGroupAccess";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    public async deleteGroupAccess(del: any) {
        var apiMethod = "Authorization/DeleteGroupAccess";
        var url = (this.apiBaseUrl + apiMethod);

        const options = {
            headers: this.httpOptions.headers,
            body: JSON.stringify(del),
        };

        return this.httpClient.delete(url, options)
            .pipe(
                catchError(this.errorHandler)
            );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}