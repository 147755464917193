<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">User Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="uForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Logon</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtLogon" class="matInputCls" name="text"
                                    formControlName="logon_id" maxlength="120">
                                <mat-error class="text-danger" *ngIf="uForm.controls['logon_id'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="uForm.controls['logon_id'].errors?.['pattern']">
                                    Logon ID should be an email!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Full Name</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtFullName" name="text" class="matInputCls"
                                    formControlName="full_name" maxlength="250">
                                <mat-error class="text-danger" *ngIf="uForm.controls['full_name'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Short Name</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtShortName" name="text" class="matInputCls"
                                    formControlName="short_name" maxlength="50">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">User Email</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtEmail" name="text" formControlName="user_email"
                                    maxlength="200" class="matInputCls">
                                <mat-error class="text-danger"
                                    *ngIf="uForm.controls['user_email'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="uForm.controls['user_email'].errors?.['pattern']">
                                    Invalid email!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Job Function</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtJobFunc" name="text" class="matInputCls"
                                    formControlName="job_function" maxlength="250">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Project Site</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="site_id" class="matInputCls">
                                    @for (sItem of sModelList; track sItem) {
                                    <mat-option [value]="sItem.site_id">{{sItem.site_code}} -
                                        {{sItem.site_description}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>

                    </div>
                    <!-- Address block -->
                    <div class="side-container">
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel">Address</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <textarea matInput id="txtAddress" rows="3" class="matInputCls"
                                    formControlName="address" maxlength="500"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Country</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <!-- <input matInput type="text" id="txtCountry" name="text" class="matInputCls"
                                    formControlName="country" maxlength="120"> -->
                                <mat-select formControlName="country" class="matInputCls">
                                    @for (cItem of cModelList; track cItem) {
                                    <mat-option [value]="cItem.country_name">{{cItem.country_name}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Zip Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="number" id="txtZippost" name="text" class="matInputCls"
                                    formControlName="zipcode" maxlength="12">
                            </mat-form-field>
                        </div>

                        <!-- <div class="item-container">
                            <mat-label class="matLabel">Telephone</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="country_code">
                                    @for (cItem of cModelList; track cItem) {
                                    <mat-option [value]="cItem.country_code">{{cItem.country_code}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <div class="small">
                                <input matInput type="text" id="txtTelephone" name="tel" class="matInputCls"
                                    maxlength="12" formControlName="telephone">
                                <mat-error class="text-danger" *ngIf="uForm.controls['telephone'].errors?.['pattern']">
                                    Invalid Telephone!
                                </mat-error>
                            </div>
                        </div> -->
                        <div class="item-container">
                            <mat-label class="matLabel">Telephone</mat-label>
                            <mat-form-field appearance="outline" class="small matPhoneField">
                                <ngx-mat-intl-tel-input [preferredCountries]="['us', 'gb']" [enablePlaceholder]="false"
                                    [enableSearch]="true" name="phone" describedBy="phoneInput"
                                    formControlName="telephone" style="padding-left: 0px;font-size: 12px;"></ngx-mat-intl-tel-input>

                                    <mat-error class="text-danger" *ngIf="uForm.controls['telephone'].errors?.['validatePhoneNumber']">
                                        Invalid Telephone!
                                    </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Fax</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="number" id="txtFax" name="text" class="matInputCls"
                                    formControlName="fax" maxlength="12">

                                <mat-error class="text-danger" *ngIf="uForm.controls['fax'].errors?.['pattern']">
                                    Invalid Fax number!
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" [disabled]="!isSaveEnable" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>

<div class="card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">All Site Information</span>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <table mat-table [dataSource]="allSiteInfoList" class="mat-elevation-z8">
                <ng-container matColumnDef="site_code" sticky>
                    <!-- <th style="border: 1px solid black;" mat-header-cell *matHeaderCellDef> Site Code </th>
                <td style="width: 1%; border: 1px solid black;" mat-cell *matCellDef="let element"> {{element.site_code}} </td> -->
                    <th mat-header-cell *matHeaderCellDef>Site Code</th>
                    <td style="width: 1%;" mat-cell *matCellDef="let element"> {{element.site_code}} </td>
                </ng-container>

                <ng-container matColumnDef="site_description">
                    <!-- <th style="border: 1px solid black;" mat-header-cell *matHeaderCellDef> Site Description </th>
                <td mat-cell style="width: 2%; border: 1px solid black;" *matCellDef="let element"> {{element.site_description}} </td> -->
                    <th mat-header-cell *matHeaderCellDef>Site Description</th>
                    <td style="width: 2%;" mat-cell *matCellDef="let element"> {{element.site_description}} </td>
                </ng-container>

                <tr mat-header-row class="clsMatRow" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="clsMatRow" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>