import { Component, OnInit } from '@angular/core';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { CommonModule } from '@angular/common';
import { EmployeeTypeService } from '../../../../../APIServices/master/weld/employee-type.service';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';
import { utility } from '../../../../../util';

@Component({
  selector: 'app-employee-type-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './employee-type-list.component.html',
  styleUrl: './employee-type-list.component.scss'
})
export class EmployeeTypeListComponent implements OnInit {
  dModelList: any;
  title: any = "Employee Type";
  selectedInfo: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Emp Type', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Emp Type', path: '', iconName: '', action: () => this.DeActivate(), hidden: false },
  ];

  /**
   *
   */
  constructor(private employeeTypeService: EmployeeTypeService,
              private router: Router
  ) {    
    
  }

  async ngOnInit() {   
    await this.Index();
  }

public async Index() {
  
  (await this.employeeTypeService.getAll()).subscribe((apiResponse: any) => {
    this.dModelList = utility.extractJsonData(apiResponse, 0);
  });
}

onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.employeeTypeService.setSelectedInfo(this.selectedInfo);
}

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./employeetypedetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.employee_type_id;

    let params = {
      employee_type_id: selectedID
    };
    this.router.navigate(['./employeetypedetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);

      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

      uModel.site_id = loggedInUser.site_id;
      uModel.site_code = loggedInUser.site_code;
      uModel.site_description = loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
      this.employeeTypeService.setSelectedInfo(uModel);
      e.event?.preventDefault();
  };

  RouteToCreate(){
    this.employeeTypeService.setSelectedInfo(null);
    this.router.navigate(['./employeetypedetails']);
  }

  async DeActivate(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Employee Type record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Employee Type?")) {
      return;
    }

    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //var empTypeList = this.selectedInfo;
    let params = {
      employee_type_list: this.selectedInfo
    };

    // var userInfoDelete = {
    //   userList: this.selectedInfo//,
    //   //requester_id: loggedInUser.logon_id
    // };

    (await this.employeeTypeService.Delete(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
}
