import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { utility } from "../util";
import { BehaviorSubject, catchError, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LookupService {
    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();

    public isNewAccess: any;
    weldApiBaseUrl: string;
    constructor(private httpClient: HttpClient) {
        this.apiBaseUrl = this.environmentConfig.apiBase;
        this.weldApiBaseUrl = this.environmentConfig.weldApiBase;
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

    public async getDiscipline(requester_id: any, site_id: any) {
        var apiMethod = "Lookup/Discipline";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }
    public async getTestProcedure(requester_id: any, site_id: any, job_id: any, item_id: any, pd_id: any, test_id: any) {
        //  job_id = 1;item_id=1;pd_id=1;test_id=1;

        var apiMethod = "ProjectTestInfo/testprocedure";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&job_id=" + job_id + "&item_id=" + item_id + "&pd_id=" + pd_id + "&test_id=" + test_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getAcceptanceCriteria(requester_id: any, site_id: any, job_id: any, item_id: any, pd_id: any, test_id: any) {
        // job_id = 1;item_id=1;pd_id=1;test_id=1;
        var apiMethod = "ProjectTestInfo/testcriteria";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&job_id=" + job_id + "&item_id=" + item_id + "&pd_id=" + pd_id + "&test_id=" + test_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getTestsTypes(requester_id: any, site_id: any) {
        var apiMethod = "ProjectTestInfo/testtype";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

    public async getTestCategory(requester_id: any, site_id: any) {
        var apiMethod = "ProjectTestInfo/testcategory";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
    }

     public async getProjectStatus (requester_id: any, site_id: any){
        var apiMethod = "Lookup/ProjectStatus";
        var param = "?requester_id=" + requester_id + "&site_id=" + site_id;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
     }

     public async getResourceTypeList (){
        var apiMethod = "resourceinfo/GetResourceType";
        var url = this.weldApiBaseUrl + apiMethod;
        return this.httpClient.get(url);
     }

     public async getResTypeCraftList(resource_type_id: any) {
        var apiMethod = "resourceinfo/GetResourceTypeCraft";
        let param = "?resource_type_id=" + resource_type_id;
        var url = this.weldApiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
     }

     public async getResStatusList(resource_type_id: any) {
        var apiMethod = "resourceinfo/GetResourceStatus";
        let param = "?resource_type_id=" + resource_type_id;
        var url = this.weldApiBaseUrl + apiMethod + param;
        return this.httpClient.get(url);
     }

     public async getEmpTypeList(site_id: any) {
        var apiMethod = "employeetypeinfo/GetAll";
        var param = "?site_id=" + site_id;
        var url = this.weldApiBaseUrl + apiMethod + param;  
        return this.httpClient.get(url);
     }

     public async getEmpLocationList(site_id: any) {
        var apiMethod = "locationinfo/GetAll";
        var param = "?site_id=" + site_id;
        var url = this.weldApiBaseUrl + apiMethod + param;  
        return this.httpClient.get(url);
     }

     public async getProcessGroupList(site_id: any) {
        var apiMethod = "processGroupinfo/GetAll";
        var param = "?site_id=" + site_id;
        var url = this.weldApiBaseUrl + apiMethod + param;  
        return this.httpClient.get(url);
     }
     public async getMaterialTypeList(site_id: any) {
        var apiMethod = "MaterialTypeInfo/GetAll";
        var param = "?site_id=" + site_id;
        var url = this.weldApiBaseUrl + apiMethod + param;  
        return this.httpClient.get(url);
     }

     public async getSRCodeList (){
        var apiMethod = "weldprocedureInfo/GetSRCodes";
        var url = this.weldApiBaseUrl + apiMethod;
        return this.httpClient.get(url);
     }


    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

}