<form class="dialog-form">
    <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <span class="dialogHeader">Map User to Project</span>
        <button mat-icon-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <span></span>
        <div class="item-container clsFloatinglabel">
            <mat-form-field appearance="outline" class="small clsFloatingField">
                <mat-label class="matInputCls">Select Project</mat-label>
                <mat-select [(ngModel)]="selectedList" [ngModelOptions]="{standalone: true}" multiple>
                    <ng-container>
                        @for (pItem of dModelList; track pItem) {
                        <mat-option [value]="pItem.project_id">{{pItem.project_code}} -
                            {{pItem.project_title}}</mat-option>
                        }
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mr-16">
        <button mat-raised-button color="primary" (click)="MapUserToProject()">Map</button>
    </mat-dialog-actions>
</form>