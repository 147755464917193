import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { CommonModule } from '@angular/common';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { WeldService } from '../../../../../APIServices/WeldService';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from '../../../../../APIServices/LookupService';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { utility } from '../../../../../util';
import { UtilityService } from '../../../../../APIServices/UtilityService';

@Component({
  selector: 'app-weld-procedure-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './weld-procedure-list.component.html',
  styleUrl: './weld-procedure-list.component.scss'
})
export class WeldProcedureListComponent implements OnInit{
  title: any = "Weld Procedure";
  weldProcedureModelList: any;
  selectedInfo: any;
  loggedInUser: any;
  toolbarItems: any;
  currentUrl: string;
  buttonAccess: any;

  constructor(
    private weldService: WeldService,
    private router: Router,
    private lookupService: LookupService,
    private utilService: UtilityService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    await this.index();
   // await this.populateResourceOptionList();
  }

  public async index() {
    (await this.weldService.getAllWeldProcedure()).subscribe((apiResponse: any) => {
      this.weldProcedureModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
      this.buttonAccess = resData[0];
      this.buttonInitialization(resData[0]);
    });
}

 buttonInitialization(buttonAccess: any) {
  console.log('buttonAccess',buttonAccess.can_edit);
    this.toolbarItems = [
      { label: 'Add Weld Procedure', path: '', iconName: '', action: () => this.addWeldProcedure(), hidden: !buttonAccess.can_add },
    { label: 'Inactivate Weld Procedure', path: '', iconName: '', action: () => this.deleteWeldProcedure(), hidden: !buttonAccess.can_delete }, 
    ];
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.weldService.setSelectedInfo(this.selectedInfo);
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./weldproceduredetails']);
    }
    var selectedID = e.row?.data.weld_procedure_id;
    let params = {
      weld_procedure_id: e.row?.data.weld_procedure_id,
      site_id: e.row?.data.site_id
    };
    this.router.navigate(['./weldproceduredetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
    this.weldService.setSelectedInfo(uModel);
    e.event?.preventDefault();
  };

  addWeldProcedure(){
    // console.log('this.selectedResource',this.selectedResource);
     this.weldService.setSelectedInfo(this.selectedInfo);
     this.router.navigate(['./weldproceduredetails']);
   }
 
   async deleteWeldProcedure() {
     if (this.selectedInfo == null || this.selectedInfo == undefined ||
       this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
       alert("Please select a weld procedure");
       return;
     }
 
     if (!confirm("Are you sure to Inactivate selected weld procedures?")) {
       return;
     }
 
     var weldprocedureInfoDelete = {
       weld_procedure_list: this.selectedInfo,
       site_id: this.loggedInUser.site_id
     };
 
     (await this.weldService.deleteWeldProcedure(weldprocedureInfoDelete)).subscribe((apiResponse: any) => {
       utility.responseAlert(apiResponse);
       this.index();
     });
   }

 /*  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Weld Procedure', path: '', iconName: '', action: () => this.addWeldProcedure(), hidden: false },
    { label: 'Inactivate Weld Procedure', path: '', iconName: '', action: () => this.deleteWeldProcedure(), hidden: false }, 
  ]; */
}
