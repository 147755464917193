import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../../util';
import { LookupService } from '../../../../../APIServices/LookupService';
import { WeldService } from '../../../../../APIServices/WeldService';
import { Console } from 'console';


@Component({
  selector: 'app-weld-procedure-details',
  standalone: true,
  imports: [ 
    MaterialModule,
    ReactiveFormsModule,
    CommonModule],
  templateUrl: './weld-procedure-details.component.html',
  styleUrl: './weld-procedure-details.component.scss'
})
export class WeldProcedureDetailsComponent {
  weldProcedureForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  loggedInUser: any;
  disciplineList: any[] = [];
  srCodeList:any[] = [];
  statusList:any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private weldService: WeldService
  ) {
      this.initializeForm(null);
  }
  
  ngOnInit() {
    this.weldService.getSelectedInfo.subscribe(x => {
      if(utility.isObject(x)) {
        this.selectedInfo = x;
      } 
    });
    
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.index();
    this.weldProcedureForm.controls['base_procedure'].valueChanges.subscribe(()=>this.setWeldProcedureTitle())
    this.weldProcedureForm.controls['rev_no'].valueChanges.subscribe(()=>this.setWeldProcedureTitle())
    this.populateDisciplineOptionList();
    this.populateSRCodeOptionList();
    this.populatestatusOptionList();
    
  }

  async index() {
    this.initializeForm(this.selectedInfo);
    if (this.selectedInfo == null || this.selectedInfo == undefined || this.selectedInfo == '') {
          this.queryParams = this.route.snapshot.queryParams['weld_procedure_id'];
          if (this.queryParams != undefined) {
            (await this.weldService.getSelectedWeldProcedure(this.queryParams)).subscribe((apiResponse: any) => {
              const jExtract = utility.extractJsonData(apiResponse, 0);
              this.selectedInfo = jExtract[0];
              this.weldService.setSelectedInfo(this.selectedInfo);
              this.initializeForm(this.selectedInfo);
            });
          }
    } 
  }

   async populateDisciplineOptionList() {
    (await this.lookupService.getDiscipline(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.disciplineList = utility.extractJsonData(apiResponse, 0);
    });
  } 
  async setWeldProcedureTitle() {
    const base_procedure=this.weldProcedureForm.get('base_procedure')?.value || '';
    const rev_no=this.weldProcedureForm.get('rev_no')?.value || '';
    const weld_procedure=base_procedure + '-R' + rev_no;
    this.weldProcedureForm.get('weld_procedure_title')?.setValue(weld_procedure,{emitEvent:false});
  } 

   async populateSRCodeOptionList() {
    (await this.lookupService.getSRCodeList()).subscribe((apiResponse: any) => {
      this.srCodeList = utility.extractJsonData(apiResponse, 0);
    });
  } 
  async populatestatusOptionList() {
    this.statusList=utility.weldProcedureStatus;
  } 

  async initializeForm(wpInfo: any) {
    if (wpInfo != null) {
      this.weldProcedureForm = new FormGroup({
        weld_procedure_id: new FormControl(wpInfo?.weld_procedure_id),
        base_procedure: new FormControl({ value: wpInfo?.base_procedure, disabled: true }, [Validators.required]),
        rev_no: new FormControl({value:wpInfo?.rev_no,disabled:true}, [Validators.required]),
        weld_procedure_title: new FormControl({value:wpInfo?.weld_procedure_title, disabled: true }, [Validators.required]),
        thickness_start_rng: new FormControl(wpInfo?.thickness_start_rng,  [Validators.required]),
        thickness_end_rng: new FormControl(wpInfo?.thickness_end_rng, [Validators.required]),
        hardness_start_rng: new FormControl(wpInfo?.hardness_start_rng, [Validators.required]),
        hardness_end_rng: new FormControl(wpInfo?.hardness_end_rng, [Validators.required]),
        bore_start_rng: new FormControl(wpInfo?.bore_start_rng, [Validators.required]),
        bore_end_rng: new FormControl(wpInfo?.bore_end_rng, [Validators.required]),
        discipline_id: new FormControl(wpInfo?.discipline_id, [Validators.required]),
        sr_code_id: new FormControl(wpInfo?.sr_code_id, [Validators.required]),
        status_id: new FormControl(wpInfo?.status_id),
        wp_start_date: new FormControl(wpInfo?.wp_start_date),
        wp_end_date: new FormControl(wpInfo?.wp_end_date),
        site_id: new FormControl(wpInfo?.site_id),
        is_active: new FormControl({ value: wpInfo?.is_active, disabled: wpInfo?.is_active }),
        requester_id: new FormControl('')
    },{validators: this.dateRangeValidation})
  }
    else {
      this.weldProcedureForm = new FormGroup({
        weld_procedure_id: new FormControl(null),
        base_procedure: new FormControl('', [Validators.required]),
        rev_no: new FormControl('', [Validators.required]),
        weld_procedure_title: new FormControl('', [Validators.required]),
        thickness_start_rng: new FormControl('',  [Validators.required]),
        thickness_end_rng: new FormControl('', [Validators.required]),
        hardness_start_rng: new FormControl('', [Validators.required]),
        hardness_end_rng: new FormControl('', [Validators.required]),
        bore_start_rng: new FormControl('', [Validators.required]),
        bore_end_rng: new FormControl('', [Validators.required]),
        discipline_id: new FormControl(null, [Validators.required]),
        sr_code_id: new FormControl(null, [Validators.required]),
        status_id: new FormControl(null),
        wp_start_date: new FormControl(''),
        wp_end_date: new FormControl(''),
        site_id: new FormControl(null),
        is_active: new FormControl({ value: true, disabled: true }),
        requester_id: new FormControl('')
    },{validators: this.dateRangeValidation})
  }
}

  dateRangeValidation: ValidatorFn = (control: AbstractControl) => {
   // console.log('in date range validator fun');
    const form = control as FormGroup;
    const sDate = form.get('wp_start_date')?.value;
    const eDate = form.get('wp_end_date')?.value;
    const endDateControl = form.get('wp_end_date');
    if (sDate && eDate && new Date(eDate) < new Date(sDate)) {
     // console.log('invalid date');
     endDateControl?.setErrors({dateInvalid: true});
      return {
        dateInvalid: true 
      };
    }
    return null;;
  }

  save() {
    this.weldProcedureForm.markAllAsTouched();
    const isFormValid = this.weldProcedureForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.weldProcedureForm.value['weld_procedure_id'] === "" 
        || this.weldProcedureForm.value['weld_procedure_id'] === null 
        || this.weldProcedureForm.value['weld_procedure_id'] === undefined) {
          this.createWeldProcedure();
    }
    else {
      this.updateWeldProcedure();
    }
  }

  async createWeldProcedure() {
    this.updateFormControls();
    (await this.weldService.createWeldProcedure(this.weldProcedureForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateWeldProcedure() {
    this.updateFormControls();
   // console.log(this.resourceForm.getRawValue());
    (await this.weldService.updateWeldProcedure(this.weldProcedureForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  updateFormControls() {
    this.weldProcedureForm.controls['requester_id'].setValue(this.loggedInUser.logon_id);
    this.weldProcedureForm.controls['site_id'].setValue(this.loggedInUser.site_id);
   // this.resourceForm.controls['is_active'].setValue(this.loggedInUser.is_active);
  }

  backtoList() {
    this.router.navigate(['./weldprocedurelist']);
  }

}
