<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="cls_drpdwn">
    <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
        <mat-label>Select Project</mat-label>
        <mat-select [(ngModel)]="selectedProject" (selectionChange)="filterByProject()">
            <ng-container>
                @for (item of pModelList; track item)
                {
                <mat-option [value]="item.project_id">{{item.project_code}}-{{item.project_title}}</mat-option>
                }
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<mat-divider></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridLineClass" #dxLineClass [dataSource]="dModelList" [allowColumnReordering]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        [rowAlternationEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="40">
            <dxi-button hint="Edit selected Line Class" icon="edit" [visible]=true [disabled]=false
                [onClick]="routeToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="lineclass_id" [width]="120" caption="Lineclass ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" [width]="120" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="project_id" [width]="120" caption="Project ID" [visible]="false"></dxi-column>
        <dxi-column dataField="lineclass_code" [width]="120" caption="Line Class" [visible]="true"></dxi-column>
        <dxi-column dataField="lineclass_desc" caption="Line Class Desc" [visible]="true"></dxi-column>
        <dxi-column dataField="material_type_title" [width]="100" caption="Material Type"></dxi-column>
        <dxi-column dataField="pressure" [width]="100" caption="Pressure"></dxi-column>
        <dxi-column caption="NDT % Required" [alignment]="'center'">
            <dxi-column dataField="rt" [width]="50" caption="RT"></dxi-column>
            <dxi-column dataField="ut" [width]="50" caption="UT"></dxi-column>
            <dxi-column dataField="dp" [width]="50" caption="DP"></dxi-column>
            <dxi-column dataField="ma" [width]="50" caption="MT"></dxi-column>
            <dxi-column dataField="pa" [width]="50" caption="PA"></dxi-column>
        </dxi-column>
        <dxi-column dataField="is_active" [width]="70" caption="Is Active?" [visible]="true"
            cssClass="dxCheckBoxFormat"></dxi-column>

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>