
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { LoadingService } from '../../APIServices/loader.service';
import { Injectable, inject } from '@angular/core';
import { utility } from '../../util';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  var environmentConfig = utility.getEnvironmentData();
  const authToken = environmentConfig.apiKey;

  // Clone the request and add the authorization header
  const authReq = req.clone({
    setHeaders: {
      XApiKey: authToken,
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    }
  });

  // Pass the cloned request with the updated header to the next handler
  return next(authReq);
};

@Injectable()
export class ApiIntercepter implements HttpInterceptor {
  constructor(private loaderService: LoadingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loaderService = inject(LoadingService);
    this.loaderService.setLoading(true, request.url);
    return next.handle(request)
      .pipe(catchError((err) => {
        this.loaderService.setLoading(false, request.url);
        return err;
      }))
      .pipe(map<unknown, any>((evt: unknown) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);
        }
        return evt;
      }));
  }
}