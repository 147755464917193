import { Component } from '@angular/core';
import { ImageSliderComponent } from '../image-slider/image-slider.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [ImageSliderComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {
  slides: any[] = [
    {
      url: '../assets/img/slide1.jpg',
      title: 'Global QHSES',
      description: "McDermott believes that anything worth doing is worth doing with a pledge to excellence. We are also a purpose-driven company that chooses to lead by example—in all that we do. That’s why our organization is committed to the areas of Quality, Health, Safety, Environment and Security—what we call QHSES.",
      textposition: "Left",
      inlineStyle:"color:#fff"
    },
    {
      url: '../assets/img/slide3.jpg',
      title: 'Quality',
      description: "At McDermott, we believe that quality is everyone's responsibility. That's why our Taking the Lead with QHSES program starts with Quality. As a function, quality collaborates and supports all stakeholders in the development of processes and improvement initiatives to ensure project excellence and delivery.",
      textposition: "Middle",
      inlineStyle:"color:#fff"
    },
    {
      url: '../assets/img/slide2.jpg',
      title: 'Health & Safety',
      description: "Taking the Lead is based on a series of practical, common-sense principles – take health, for instance. Health, for obvious reasons, is preferred over sickness, both at the individual level, as well as at an organizational level. That is why McDermott actively promotes the physical, mental and emotional well-being of its employees. The reason is simple; when we feel best, we perform best.\nThe Taking the Lead program helps us maintain active engagement and mindful consideration of all things related to safety – and not just for ourselves, but for every one of the relationships that, collectively, we value. After all, rarely does a negative consequence affect one person. We demonstrate that we value life and quality of life above all else through our disciplined approach to safety.",
      textposition: "Top",
      inlineStyle:"color:#fff"
    }
    ,
    {
      url: '../assets/img/slide4.jpg',
      title: 'Environment',
      description: "We are committed to reducing our environmental footprint; optimizing resource efficiency and usage and steadily implementing energy transition initiatives across our operations worldwide. Upholding the integrity of environmental management systems and incorporating technologies that utilize resources strategically and sustainably are key in minimizing the environmental impact of our operations. Click here to learn more.",
      textposition: "Bottom",
      inlineStyle:"color:#fff"
    }
    ,
    {
      url: '../assets/img/slide5.jpg',
      title: 'Security',
      description: 'Life is full of risks; however comprehensive security solutions that continuously evaluate known risks, while planning for future risks, pave the way to the kind of working environment that produces efficiency and schedule reliability. This proactive approach to security protects our employees, our partners and the critical assets required to get the job done right on behalf of our customers.',
      textposition: "Right",
      inlineStyle:"color:#fff"
    }
    ,
    {
      url: '../assets/img/slide6.jpg',
      title: "Training & Development",
      description: 'Training is a formal process by which talent development professionals help individuals improve performance at work. Development is the acquisition of knowledge, skill, or attitude that prepares people for new directions or responsibilities.',
      textposition: "Left",
      inlineStyle:"color:black"
    }
  ];
}
