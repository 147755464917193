import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MaterialModule } from '../../core/material-module';

@Component({
  selector: 'app-session-timeout',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './session-timeout.component.html',
  styleUrl: './session-timeout.component.scss'
})
export class SessionTimeoutComponent {
  baseUrl = environment.baseURL;
}
