import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../../APIServices/MenuService';
import { FlexLayoutModule, MediaChange, MediaObserver } from '@angular/flex-layout';
import { ToolbarItem } from './toolbar-item';
import { Subscription } from 'rxjs';
import { MaterialModule } from '../../core/material-module';
import { CommonModule } from '@angular/common';
import { utility } from '../../util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar-buttons',
  standalone: true,
  imports: [FlexLayoutModule, MaterialModule, CommonModule],
  templateUrl: './toolbar-buttons.component.html',
  styleUrl: './toolbar-buttons.component.scss'
})
export class ToolbarButtonsComponent implements OnInit, OnDestroy {
  constructor(public menuService: MenuService,
    public mediaService: MediaObserver, private router: Router) { }

  @Input() items: ToolbarItem[];
  @Input() title: string;
  site_desc: any;

  watcher: Subscription;
  toolbarItems: ToolbarItem[];
  namedButtons: ToolbarItem[] = [];
  iconButtons: ToolbarItem[] = [];
  overflowMenuItems: ToolbarItem[] = [];

  ngOnInit() {
    // Assume this is a file read or HttpClient request that completes after the first event.
    this.toolbarItems = this.items;
    this.onMediaChange();

    this.watcher = this.mediaService.asObservable().subscribe((change: MediaChange[]) => {
      this.onMediaChange();
    });
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.site_desc = loggedInUser.site_description;
  }
  ngOnDestroy() {
    if (this.watcher) {
      this.watcher.unsubscribe();
    }
  }
  onMediaChange() {
    let items = this.toolbarItems.slice();
    this.namedButtons = [];
    this.iconButtons = [];
    this.overflowMenuItems = [];

    if (this.mediaService.isActive('xs')) {
      this.namedButtons = this.namedButtons.concat(items.splice(0, 1));
    } else if (this.mediaService.isActive('sm')) {
      this.namedButtons = this.namedButtons.concat(items.splice(0, 4));
    } else if (this.mediaService.isActive('md')) {
      this.namedButtons = this.namedButtons.concat(items.splice(0, 6));
    } else if (this.mediaService.isActive('lg')) {
      this.namedButtons = this.namedButtons.concat(items.splice(0, 8));
    } else if (this.mediaService.isActive('xl')) {
      this.namedButtons = this.namedButtons.concat(items.splice(0, 12));
    }

    if (items.length > 0) {
      this.overflowMenuItems = items;
    }
  }
}
