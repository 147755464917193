import { Component } from '@angular/core';
import { DevExtremeModule } from '../../../../../core/devExtreme.module';
import { MaterialModule } from '../../../../../core/material-module';
import { ToolbarButtonsComponent } from '../../../../../header/toolbar-buttons/toolbar-buttons.component';
import { Router } from '@angular/router';
import { ProcessService } from '../../../../../APIServices/master/weld/ProcessService';
import { utility } from '../../../../../util';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ToolbarItem } from '../../../../../header/toolbar-buttons/toolbar-item';
import { FormsModule } from '@angular/forms';
import { LookupService } from '../../../../../APIServices/LookupService';

@Component({
  selector: 'app-process-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, ToolbarButtonsComponent, FormsModule],
  templateUrl: './process-list.component.html',
  styleUrl: './process-list.component.scss'
})
export class ProcessListComponent {
  dModelList: any;
  dModelFilterList: any;
  title: string = 'Process';
  selectedInfo: any;
  selectedProcessGroup: any;
  lookupProcessGroupList: any;

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Process', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Process', path: '', iconName: '', action: () => this.DeActivate(), hidden: false }
  ];

  constructor(private router: Router, 
    private processService : ProcessService,
    private lookupService : LookupService) {
        
  }

  async ngOnInit(){ 
    await this.populateProcessGroupList()
    //await this.Index()
  }

  applyFilter(){
    if(this.dModelList != null && this.dModelList != undefined && this.dModelList.length > 0){
      this.dModelFilterList = this.dModelList.filter((v) => 
        v.process_group_id === (this.selectedProcessGroup == null ? 0 : this.selectedProcessGroup)
      );
    }
  }

  public async Index() {
  
    (await this.processService.getAll()).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
      this.applyFilter();
    });
  }

  private async populateProcessGroupList(){
      (await this.processService.getAllProcessGroupList()).subscribe((apiResponse: any) => {
        this.lookupProcessGroupList = utility.extractJsonData(apiResponse, 0);

        this.lookupProcessGroupList.unshift({process_group_id: 0 ,process_group_code: "-Not Assigned-", process_group_title: "Not Assigned"});

        if (this.lookupProcessGroupList != null && this.lookupProcessGroupList.length > 0){                         
              this.selectedProcessGroup = this.lookupProcessGroupList[0].process_group_id;
              this.Index();          
          };

      });
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.processService.setSelectedInfo(this.selectedInfo);
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./processdetails']);
    }

    // var user_id=this.userService.LoggedInUserEmail();
    var selectedID = e.row?.data.process_id;

    let params = {
      process_id: selectedID
    };
    this.router.navigate(['./processdetails'], { queryParams: params });
    var uModel;
    // if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);

    //   var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //   uModel.site_id = loggedInUser.site_id;
    //   uModel.site_code = loggedInUser.site_code;
    //   uModel.site_description = loggedInUser.site_description;
    // }
    // else {
    //   var localUser = JSON.stringify(e.row?.data);
    //   uModel = <any>JSON.parse(localUser);
    // }
    
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);      
      this.processService.setSelectedInfo(uModel);
      //this.router.navigate(['./processsdetails'], { queryParams: params });   
      e.event?.preventDefault();
  };

  RouteToCreate(){
    this.processService.setSelectedInfo(null);
    console.log("this.selectedProcessGroup");
    console.log(this.selectedProcessGroup);
    this.processService.setSelectedProcessGroupInfo(this.selectedProcessGroup);
    this.router.navigate(['./processdetails']);
  }

  async DeActivate(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Process?")) {
      return;
    }

    //var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //var empTypeList = this.selectedInfo;
    let params = {
      process_list: this.selectedInfo
    };

    // var userInfoDelete = {
    //   userList: this.selectedInfo//,
    //   //requester_id: loggedInUser.logon_id
    // };

    (await this.processService.Delete(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }
}
