export class siteModel{    
    site_id?:number;
    site_code?:string;
    site_description?:string; 
    time_zone?:string;
    is_active?:boolean;
    uom?:string;   
    requester_id?:string;
    base_folder?:string;
    global_folder_path?:string;
}