import { Component, OnInit } from '@angular/core';
import { utility } from '../../../util';
import { Router } from '@angular/router';
import { ProjectService } from '../../../APIServices/ProjectService';
import { projectModel } from '../../../models/projectModel';
import { UserService } from '../../../APIServices/UserService';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../core/material-module';

@Component({
  selector: 'app-user-project-map',
  standalone: true,
  imports: [FormsModule, MaterialModule],
  templateUrl: './user-project-map.component.html',
  styleUrl: './user-project-map.component.scss'
})
export class UserProjectMapComponent implements OnInit {

  selectedList: any[] = [];
  dModelList: projectModel[] = [];
  selectedInfo: any;

  constructor(private router: Router,
    private projectService: ProjectService, private userService: UserService,
    private dialogRef: MatDialogRef<UserProjectMapComponent>) { }

  async ngOnInit() {
    await this.userService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);
      
    this.populateProject();
  }

  async populateProject() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var site_id = this.selectedInfo[0].site_id;

    (await this.projectService.getAllProjectList(loggedInUser.logon_id, site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
      console.log('PopulateProject');
      console.log(apiResponse);
      if (this.selectedInfo != null && this.selectedInfo.length > 0) {
        var splitString = this.selectedInfo[0].merged_project_id.split(',');
        this.selectedList = splitString.map(Number);
      }

    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async MapUserToProject() {
    var uInfo = this.selectedInfo;

    if (uInfo == null) {
      alert("Please select user");
      return;
    }
    if (this.selectedList == null) {
      alert("Please select Project");
      return;
    }
    var uArr: any = [];
    for (let i = 0; i < uInfo.length; i++) {
      uArr.push({
        user_id: uInfo[i].user_id,
        site_id: uInfo[i].site_id
      });
    }
    var pArr: any = [];
    for (let i = 0; i < this.selectedList.length; i++) {
      pArr.push({
        project_id: this.selectedList[i],
      });
    }
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    var userProject = {
      userList: uArr,
      projectList: pArr,
      requester_id: loggedInUser.logon_id,
    };

    //Call API to update mapping
    (await this.userService.mapUserToProject(userProject)).subscribe((apiResponse: any) => {
      console.log(apiResponse);
      utility.responseAlert(apiResponse);
      //this.Index();
    });
  }

}
