import { Component } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-notification-page',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './notification-page.component.html',
  styleUrl: './notification-page.component.scss'
})
export class NotificationPageComponent {
  baseUrl = environment.baseURL;
}
