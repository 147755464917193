import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { utility } from "../../../util";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class LineClassService {
    apiEndPoint: string = "LineClassInfo/"
    requester_id: string;
    site_id: string;
    site_code: string;
    private apiBaseUrl: string;
    private apiGeneUrl: string;
    private selectedInfo = new BehaviorSubject<any>(null);
    getSelectedInfo$ = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();

    constructor(private httpClient: HttpClient, private router: Router) {
        this.apiBaseUrl = this.environmentConfig.weldApiBase;
        this.apiGeneUrl = this.environmentConfig.apiBase;

        var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
        this.requester_id = loggedInUser.logon_id
        this.site_id = loggedInUser.site_id
    }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }
    public async getAllLineClassList(project_id: any) {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)

        var apiMethod = this.apiEndPoint + "GetAll";
        //var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&project_id=" + project_id;
        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params });
    }
    public async getSelectedLineClass(lineclass_id: any,project_id: any) {
        const params = new HttpParams()
            .set('lineclass_id', lineclass_id)
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('project_id', project_id)

        var apiMethod = this.apiEndPoint + "detail";
        //var param = "?requester_id=" + requester_id + "&lineclass_id=" + lineclass_id + "&site_id=" + site_id + "&project_id=" + project_id;
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }
    public async createLineClass(post: any) {
        var apiMethod = this.apiEndPoint + "Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            )
    }
    public async updateLineClass(put: any) {
        var apiMethod = this.apiEndPoint + "Update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            )
    }
    public async deleteLineClass(del: any) {
        var apiMethod = this.apiEndPoint + "Delete";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
            .pipe(
                catchError(this.errorHandler)
            )
    }

    public async getActiveProjectList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)

        var apiMethod = "ProjectInfo/GetAll";
        //var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&is_active=true";
        var url = this.apiGeneUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    public async getActiveMaterialTypeList() {
        const params = new HttpParams()
            .set('requester_id', this.requester_id)
            .set('site_id', this.site_id)
            .set('is_Active', true)

        var apiMethod = "MaterialTypeInfo/GetAll";
        //var param = "?requester_id=" + requester_id + "&site_id=" + site_id + "&project_id=" + project_id;
        var url = this.apiBaseUrl + apiMethod;
        return this.httpClient.get(url, { params: params }).pipe(
            catchError(this.errorHandler)
        );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}