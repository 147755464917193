<!-- <p>home-page works!</p>
<a routerLink="layoutpage">skip to main content</a>
<button mat-icon-button>skip to main</button>
 -->
<div style="
 display:flex;
 align-items: start;
 justify-content: center;">
    <app-image-slider [indicatorsVisable]="true" [autoPlay]="true" [slides]="slides">
    </app-image-slider>
</div>