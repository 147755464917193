<div class="slides">
    <div class="slide" 
    (mousedown)="onMouseDown()" 
    (mouseup)="onMouseUp()"
    (mouseleave)="onMouseUp()"
    [ngStyle]="{'background-image':'url('+ slides[currentSlide].url +')',
    opacity: hidden ? 0 : 1,
    'transition':'opacity ' + animationSpeed + 'ms ease-in-out'}">
        <!-- <div class="user-info">{{userName}}</div> -->
        <div class="slide-title-{{slides[currentSlide].textposition.toLowerCase()}}">
            {{slides[currentSlide].title}}
        </div>
        <br />
        <div class="slide-desc-{{slides[currentSlide].textposition.toLowerCase()}}">
            {{slides[currentSlide].description}}
        </div>
        @if(indicatorsVisable){
        <div id="indicators">
            @for(slide of slides; track $index){
            <div class="indicator" (click)="jumpToSlide($index)" [ngClass]="{active:$index == currentSlide}"></div>
            }
        </div>
        }

        <button (click)="next()" class="nextButton">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
        <button (click)="previous()" class="previousButton">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
    </div>
</div>