<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
        </mat-card-header>
        <mat-card-content>
            <div class="text-center">
                <p class="p-error text-gray-500 mb-0">Page is under construction</p>
                <a href="{{baseUrl}}">&larr; Back to Landing Page</a>
            </div>
        </mat-card-content>
    </mat-card>
</div>