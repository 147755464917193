<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="cls_drpdwn flex-container">
    <div>
        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
            <mat-label>Master Type</mat-label>
            <mat-select [(ngModel)]="selectedMasterType" (selectionChange)="applyFilter()">
                <ng-container>
                    @for (item of lookupMaterTypeList; track item) {
                    <mat-option [value]="item.master_type_id">{{item.display_description}}</mat-option>
                    }
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
   <!--  <div>
        <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
            <mat-label>Select Status</mat-label>
            <mat-select [(ngModel)]="selectedStatusFilter" (selectionChange)="applyFilter()">
                <ng-container>
                    @for (item of resStatusOptionList; track item) {
                        <mat-option [value]="item.resource_status_id">{{item.resource_status}}</mat-option>
                    }
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>  -->    
</div>
<mat-divider class="divider_cls"></mat-divider>
<div class="centered-outer-div">
    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="wmModelFilterList"
        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true" 
        (onSelectionChanged)="onSelectionChanged($event)">
        <dxo-selection mode="multiple" showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Resource" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="master_id" [width]="100" caption="Master ID" [visible]="false"></dxi-column>
        <dxi-column dataField="code" [width]="120" caption="Code" [visible]="true" sortOrder="asc" ></dxi-column>
        <dxi-column dataField="description" [width]="120" caption="Description" [visible]="true"></dxi-column>        
        <dxi-column dataField="is_active" [width]="80" caption="is Active?" [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>
        

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true" [visible]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-data-grid>
</div>