import { AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { isPlatformBrowser } from "@angular/common"; // update this
import { utility } from '../../util';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-image-slider',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './image-slider.component.html',
  styleUrl: './image-slider.component.scss'
})
export class ImageSliderComponent implements OnInit, AfterViewInit {
  @Input() slides: any[];
  @Input() indicatorsVisable = true;
  @Input() autoPlay = true;
  animationSpeed = 500;
  autoPlaySpeed = 5000;
  currentSlide: number = 0;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  hidden = false;
  isBrowser = signal(false);  // a signal to store if platform is browser
  userName: string;
  autoplaySubscription: Subscription | null = null;
  timeoutId: any;
  constructor(@Inject(PLATFORM_ID) platformId: object, private router: Router) {
    this.isBrowser.set(isPlatformBrowser(platformId));
  }

  ngOnInit() {
    /* if (this.autoPlay) {
      if (this.isBrowser()) {
        setInterval(() => {
          this.next();

          //Assigning Welcome Name
          if (localStorage != null && localStorage != undefined
            && localStorage.getItem("loggedInUser") != null 
            && localStorage.getItem("loggedInUser") != undefined) {
            var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
            if (loggedInUser != null && loggedInUser != undefined) {
              this.userName = 'Welcome ' + loggedInUser.full_name;
            }
          }

        }, this.autoPlaySpeed);
      }
    } */
   this.startAutoplay();
   if (localStorage != null && localStorage != undefined
    && localStorage.getItem("loggedInUser") != null 
    && localStorage.getItem("loggedInUser") != undefined) {
      var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
      if (loggedInUser != null && loggedInUser != undefined) {
        this.userName = 'Welcome ' + loggedInUser.full_name;
      }
    }
  }

  startAutoplay() {
    if(this.autoplaySubscription) {
      this.autoplaySubscription.unsubscribe();
    }
    this.autoPlay = true;
    this.autoplaySubscription = interval(this.autoPlaySpeed).subscribe(() => {
      if(this.autoPlay) {
        this.next();
      }
    });
  }

  stopAutoplay() {
    this.autoPlay = false;
    if(this.autoplaySubscription) {
      this.autoplaySubscription.unsubscribe();
      this.autoplaySubscription = null;
    }
  }

  onMouseDown() {
    this.stopAutoplay();
  }

  onMouseUp() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.startAutoplay();
    }, 3000)
  }

  ngAfterViewInit(): void {

  }
  next() {
    let currentSlide = (this.currentSlide + 1) % this.slides.length;
    this.jumpToSlide(currentSlide);
  }
  previous() {

    let currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    this.jumpToSlide(currentSlide);
  }
  jumpToSlide(index: number) {
    this.hidden = true;
    setTimeout(() => {
      this.currentSlide = index;
      this.hidden = false;
    }, this.animationSpeed);
  }

  ngOnDestroy() {
    this.stopAutoplay();
    clearTimeout(this.timeoutId);
  }
}
