<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Test Procedure</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtpsID" name="text" class="matInputCls"
                                    formControlName="ps_id">
                            </mat-form-field>
                        </div>
                        <!-- Job -->
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix mt-1e asterix">Job</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtjobcode" name="text" class="matInputCls"
                                    formControlName="job_code" Maxlength="2">                                
                            </mat-form-field>
                        </div>

                        <!-- Item -->
                        <div class="item-container">
                            <mat-label class="matLabel asterix">Item</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtitemcode" name="text" class="matInputCls"
                                    formControlName="item_code" maxlength="20">
                                
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Discipline</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="disc_id">
                                    <ng-container>
                                        @for (Item of lookupDisciplineList; track Item) {
                                        <mat-option [value]="Item.disc_id">{{Item.disc_title}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Test Type</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select formControlName="test_id">
                                    <ng-container>
                                        @for (Item of lookupTestTypeList; track Item) {
                                        <mat-option [value]="Item.test_id">{{Item.test_description}}</mat-option>
                                        }
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Base Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtbaseprocedure" name="text" class="matInputCls"
                                    formControlName="base_procedure">                                
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Test Procedure</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txttestprocedure" name="text" class="matInputCls"
                                    formControlName="test_procedure">                                
                            </mat-form-field>
                        </div>

                        
                        
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label> 
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                    id="chkIsActive">
                            </mat-checkbox>                 
                        </div> 
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>