export const environment = {
    production: false,
    apiBase: 'https://mcisgeneapi-dev.mcdermott.com/api/',
    weldApiBase: 'http://clddgpcm01:8001/api/',
    apiKey:'EC7667F1-BB0F-48DA-83C3-BA3F9564E35A',
    env: 'dev',
    clientId:'10645fb8-b059-40d8-b504-f09fb1968a31',
    authority: 'https://login.microsoftonline.com/73faca80-f981-4662-85da-6c374785348d',
    redirectUri: 'https://mcis-dev.mcdermott.com/',
    baseURL:'https://mcis-dev.mcdermott.com/',
    appVersion: require('../../package.json').version,
};
