
<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <!-- <span class="card-title">Session Timeout</span> -->
        </mat-card-header>
        <mat-card-content>
            <div class="text-center">
                <div class="div-error mx-auto" data-text="404">404</div>
                <p class="p-error lead text-gray-800 mb-5">Session Timeout</p>
                <p class="p-error text-gray-500 mb-0">It looks like your session has expired. Please relogin</p>
                <a href="{{baseUrl}}">&larr; Back to Landing Page</a>
            </div>
            
        </mat-card-content>
    </mat-card>
</div>