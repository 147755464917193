import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { TopNavBarComponent } from './header/top-nav-bar/top-nav-bar.component';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { HomePageComponent } from './home/home-page/home-page.component';
import { MenuService } from './APIServices/MenuService';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { MSAL_Module } from './core/msal.module';
import { AuthenticationResult, EventType } from '@azure/msal-browser';


config({ licenseKey });

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
    LoaderComponent,
    TopNavBarComponent,
    HomePageComponent,
    MsalModule,
    MSAL_Module,],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'MCIS.UI';
  constructor(private menuService: MenuService, private msalservice: MsalService) {
    // this.msalservice.instance.initialize();
    // this.login();
  }
  async ngOnInit() {

    // this.msalservice.instance.handleRedirectPromise().then(
    //   res => {
    //     if (res != null && res.account != null) {
    //       this.msalservice.instance.setActiveAccount(res.account)
    //     }
    //   }
    // )
    
  }
  
}
