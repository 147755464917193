import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { MsalService } from '@azure/msal-angular';
import { SiteService } from '../../../APIServices/SiteService';
import { utility } from '../../../util';
import { siteModel } from '../../../models/siteModel';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { UtilityService } from '../../../APIServices/UtilityService';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-site-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './site-list.component.html',
  styleUrl: './site-list.component.scss'
})
export class SiteListComponent implements OnInit {
  dModelList: any[] = []
  title: any = "Site Management";
  toolbarItems:any;
  currentUrl: string;
  buttonAccess: any;

  constructor(
    private siteService: SiteService,
    private router: Router,
    private utilService: UtilityService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.currentUrl = this.route.snapshot.url[0].path;
    this.getButtonsAccess(this.currentUrl);
    this.Index();
  }

  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getAllSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async getButtonsAccess(currRoute: any) {
    (await this.utilService.getAllButtonAccess(currRoute)).subscribe((apiResponse: any) => {
      let resData = utility.extractJsonData(apiResponse, 0);
        this.buttonAccess = resData[0];
        this.buttonInitialization(resData[0]);
    });
}

 buttonInitialization(buttonAccess: any) {
    console.log('buttonAccess',buttonAccess);
    this.toolbarItems = [
      { label: 'Add Site', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: !buttonAccess.can_add }
    ];
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./sitedetails']);
    }

    var selectedID = e.row?.data.site_id;

    let params = {
      site_id: selectedID
    };
    this.router.navigate(['./sitedetails'], { queryParams: params });
    var dModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);

      var localUser = JSON.stringify(localStorage.getItem('loggedInUser'));
      var loggedInUser = JSON.parse(JSON.parse(localUser));
      dModel.site_id = loggedInUser.site_id;
      dModel.site_code = loggedInUser.site_code;
      dModel.site_description = loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
    }
    this.siteService.setSelectedInfo(dModel);
    e.event?.preventDefault();
  }

  RouteToCreate() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var dModel;
    // if (loggedInUser.site_id == 1 || loggedInUser.site_code == 'GLB') {
    //   dModel = null;
    // }
    // else {
    //   dModel = {
    //     site_id: loggedInUser.site_id,
    //     site_code: loggedInUser.site_code,
    //     site_description: loggedInUser.site_description
    //   }
    // }
    this.siteService.setSelectedInfo(dModel);
    this.router.navigate(['./sitedetails']);
  }
}
