import { InjectionToken } from '@angular/core';
 
export type EnvironmentConfiguration = {
    production:boolean;
    apiBase: string;
    weldApiBase: string,    
    apiKey: string;
    env: string;
    clientId: string;
    authority: string;
    redirectUri: string;
    baseURL: string;
};
export const ENVIRONMENT_CONFIG = new InjectionToken<any>(
  'EnvironmentConfiguration'
);

//Local config with API connecting to DNS Like 'https://mcisgeneapi-dev.mcdermott.com/api/'
//U2FsdGVkX18Lu5DaOWP9A1Rk5LTRm6Fj/B5Xkas5ztNlePcgnY/+IfOXLv+ICLdqzDAEozhheIfBoja3+vbntfZNfsJBhm/e4FjlGXX/1bCFOzgU6Iv/PYqFOmVZRNak1GtIruYTXDFLSvIPpuuH6YcvpHDklx+kqTjx5MpGo7JkIR8+mzHnO2FuBck7gtngL9aFoQgUOqhx/HQfi9dg8j+YulAjhLDQz1k+QFuk5qY1hk2OpiC6/7JSTdlwBF8oGBOGto04qmeiQb7H5hR3BgDTAz6/SQ7dw8fzgoENyGqlejUM/aottj7in9nKOJ27acQIUybg6tkB8MzBjaOJsDBhrk+5GZLRjEIzUI+hABDl0O4ZzsjUAWn6JYibmqDJs26X9ipAs5t2J4Pse512NHrLDyGG+LoD34EepBEy8iUm8OC8cPZIY4zAAGxo7xk3K2dzdKerrm8YlFeFkpmcO4Xq9UtGKJTzFQ+v9aIkPqSUJyaxtNzCPioBxKvcVbBtNQTLeCM95sYNIRiywPLo+5jYuxBzL5h0tiyQzCRWxpPGdgQ5RbRK+xGR0BE9fnTav/8vo4UCBabhCy7cIJMEuw2f/aWupvkYZIWmnJoMSB7wBFmeq52l86047I1F197oBEH1U2ggiSVzrNxQTBB73SI+6zvmLxlBIl4aM7cwTTF1SfFQadvr+wKR0uOQ8da4QWGSWVFS1yMLC6Hup+4M/w==

//Local config with API connecting to Local Like 'http://localhost:5212/api/'
//U2FsdGVkX189vxNmtVh/MIZIn2mWHKAb5hfAGM8NZ4tuxudIzNL1L9tk+qttnvlZLhBtLXIdJtvH0Donm6gsxbqC7rNXfuuem7zztbmKbT/dfLPxAQjHGeXeeZF7DgdHiTSoQnoT18PGrcu2uHEgjaSLkaGGUnoMdouqZUVsm2vIL34CHysyfzD0fWJSn3yhFDHlij/nAJBwwA5hyAfXJr3MbJ5JTeD9ZVhCr7ZibIdDA4DHs2SHl5n7LV9wUPnohG3bkPHYQQRXt3WvFs7psJsJPUySnUzKmQVQ40My3bkHtSbgqtqxh/nDoJedx7uCRyVr0Inxb7/KVHH8CY9kS2O2Bchf2+IYeTnEsVwzlrSBeWzKUB70725hyS+lZxFlopvENno/DAakMzPJXQM1n550dctH7/wdKPSQEEnZpUmpeassdbeUlmk/WGlnhCh5mivMO9+64GemMXSScYbAHDahDtuP207pCR8kkB2f/2nWTkoLBd6Mj6r9bgVjtBTxbWfFI1Nj+A23OaYjjihB8A==

//Development server Configuration
//U2FsdGVkX1+qggpRu3XUWDEgEZRTJqHIMzE4z7nC9aWIODxAfc/u5/UrRwbx2pe+JcLm8Sj9wlFS7AA0QL6VhTaFkRyXNnYeDJKkWbk8z08eIaqlvUWzinqLYyGzL5487FjYBSIlzdFgRWHSaeMPMi/sRfMupYN3an1hnhuvvq0GxNvvGYfCZ62BycOeJi4aYLCp1jcDXNQRW2A8J4OzKSvYq4v3SXCIEv/D7Ap9c4VCkOBM1K0CxGlwTDstJwA2owIAg3BTL5vZm9lj7ddGzFeBEYFyOflFFv6oE66UHnj9WQSF1CaIRq8+8hzBdIrZLdlI2RrjLdgO2QoTusgETj9K0wYa4nZx0zpnweXNXRcrHfHmdVKSSM+k91oOmDCFtakTfYs0YfeOMa97WtFMJvpNsRLSWma4CWSVkj3/5t5k0BCVjXCk9kXGS4SjU9JUy8d+DRr6Tly6jCfPu1h+KrUNKYaA2jrQbuF+vPW7OqltIKMsm9JoAH+lW4naAgT29jU57GvW3RRL9SF300L+hgsmVcIkrYjmN3rHgFhBbGs3w0pnnAsqK4dEKfYav5yWMZ385DntJCs29BnJCDCjEjomHJx10E7g+zpqS8efRQUYcpS6R8BKmZUFBuwNm6DPQF5O/9yC2yEyYesxgLmid/zFPHnUoy2343VmFBbKoXwew5G2F5jEX8v+f3tqVda7NqAAo+F1OXj4qOMsaZTsw3K2iez29YG6ZPS/JNsoKjo=

//QA server Configuration
//U2FsdGVkX18t/IKmegVUcnDM5wuC9KvCGOyw1t7fSa+AOt3gMyAZgoag3X9RwkiclYytBNii5fz0qL/dOWHbyDNrUeg8QS8eQ8JESzQ05lYDhOjHQYC18Y2J8XL/Q7UvXRN3c1KfXbIBgsb4HU2DJoO0Y0XxWKomuKWNdYTKF4ubX3hLgJ9UOBVR94yJLfV4VPpGpG2emJz4mc/qTIGYOWphJo+BCDNQewOh5lqxkOikAFQd/GxLQY47nXPoy7cxNU4/3CeCdwYnvDi2QtF+q2jWRBpTxn9Vf8juhOpe6Ep6vwW78QtXSrR5lCDD2CBLhQBRUlbJatO/HSy8baIaZrBDF90xO8rNi3fOZCrQyusFDvE7EsAcfr7Ns4+m0fCrBx5bWY9ySHWivE8QBbWQVrjDEB/pJbgOjK/1d7I3EraKNCU2r0UgSI6XOyOeSc1gGfCo1QZxIqPoO8wuegN5eXqg31LFIPGJ3JxpRpHSFgbK+4FrCTR8DUW04tQRkM3ZdYXbcJd3lKc54o3I9T400vf2K/4OXKF8OaiMFvR50kBHN8ppU9Y//KgOpzTvLYOMKRGx/WnwK78hHRR7z1axtQQEVbk39751oSm/08Wp23nVNqXtkTA3otZwhxptg7L+lP9pIj8HN0FCkEqGxEMlS0oL2Shdg3iLTyjkZ/kO8Hoxi1nIP0Tu9kY7I6Z+DvvVDb09epQIWj9vjeVbTaDqOg==

//Prod configurations
//U2FsdGVkX18DAxzwJkIOzKMmS40SntYfCP2ws3xTrZyg2f/M+JarkR7viXkN7ezLMefBE85RpDYlkeZJlKYY9Hjlciblfd/tas4iNg7EjikI6K/v3E0D1LGZgnxd67yQeDqUmO3qaoJ6yq+FlF8TlQSx+0MuGKJNuQ3SF3WCpoYdCtoIrxZ953uUcMA1S7a03Z8jeoPN+jEdlHdBxKPFdYH6jHw+jW3dQJsKv2lgYcSlaJjNlBuHtbwBsOOIKDMbk9dNH+OQX09FTm/FwQK1z5rIUM6Ibn6MCtrWWw4Ec0M5KFE08V3nt1q1pME0xw58J/V+WBCqBoH+Pp9KkY62j9qkVXEs30oUVfLhJ7vZ9htl01TpFBRkrZUuwRGPT15fFvQFqOxxXtFtC3lq1ulytQem3mZUbSjyrlOe+ly+Qiz2NCpSH2JCrrphwRiTlKtqH+xDE1142VjRfcTvbCs8w0CSV58FoSPG/gQJZC/u8wj0j7/bIOkbXFeRBmPrym7JxG0pnkzF+GSmVh91Q/iYxvg86Tk8BUCZcdu+g4k18S2HQ/rpb66tI2zREx+zhYxN12wOFDeuM7lkhV5ZCI/ykbt1+IEt9GXqFWGIhkqy09qG3o/XMXDzFXLSxFwuEdC1lf1kcJ7w7zvb6UehuxRcY0G5C3frZQtl27WESWGB/6E2Q302W0AaQ9T9zALPkau4