import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApiIntercepter, apiInterceptor } from './header/Interceptor/api.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from './environment.config';

// export const appConfig: ApplicationConfig = {
//   providers: [provideRouter(routes),
//   provideClientHydration(),
//   provideHttpClient(),
//   provideHttpClient(withInterceptors([apiInterceptor])),
//   provideHttpClient(withInterceptorsFromDi()),
//   {
//     provide: HTTP_INTERCEPTORS,
//     useClass: ApiIntercepter,
//     multi: true
//   }, provideAnimationsAsync(), provideAnimations()]
// };
export const appConfig = (config: EnvironmentConfiguration): ApplicationConfig => {
  return {
    //providers: [{ provide: ENVIRONMENT_CONFIG, useValue: config }],
    providers: [provideRouter(routes),
      provideClientHydration(),
      provideHttpClient(),
      provideHttpClient(withInterceptors([apiInterceptor])),
      provideHttpClient(withInterceptorsFromDi()),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiIntercepter,
        multi: true
      }, provideAnimationsAsync(), provideAnimations(),
      { provide: ENVIRONMENT_CONFIG, useValue: config }]
  };
};
