import { Component } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-faq-page',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './faq-page.component.html',
  styleUrl: './faq-page.component.scss'
})
export class FaqPageComponent {
  baseUrl = environment.baseURL;
}
