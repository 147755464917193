{
  "name": "mcis.ui",
  "version": "1.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:MCIS.UI": "node dist/mcis.ui/server/server.mjs",
    "start-prod": "ng serve --configuration prod",
    "start-development": "ng serve --configuration development",
    "start-staging": "ng serve --configuration staging",
    "start-local": "ng serve --configuration local",
    "build-prod": "ng build --configuration prod --output-hashing=all",
    "build-development": "ng build --configuration development --output-hashing=all",
    "build-staging": "ng build --configuration staging --output-hashing=all",
    "build-local": "ng build --configuration local --output-hashing=all"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.8",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.8",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/platform-server": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@angular/ssr": "^17.3.3",
    "@azure/msal-angular": "^3.0.21",
    "@azure/msal-browser": "^3.18.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.4.2",
    "@fortawesome/free-solid-svg-icons": "^6.4.2",
    "crypto-js": "^4.2.0",
    "devextreme": "24.1.3",
    "devextreme-angular": "24.1.3",
    "devextreme-schematics": "^1.6.8",
    "express": "^4.18.2",
    "libphonenumber-js": "^1.11.12",
    "material-icons": "^1.13.12",
    "ngx-mat-intl-tel-input": "^5.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.3",
    "@angular/cli": "^17.3.3",
    "@angular/compiler-cli": "^17.3.0",
    "@types/crypto-js": "^4.2.2",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "devextreme-cli": "latest",
    "devextreme-themebuilder": "23.2.6",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
