<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Data Encryption</span>
        </mat-card-header>
        <mat-card-content>
            <div>
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Input</mat-label>
                                <textarea matInput id="txtInput" rows="8" [(ngModel)]="inputData"
                                    maxlength="1000"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Result</mat-label>
                                <textarea matInput id="txtResult" rows="8" [(ngModel)]="resultData"
                                    maxlength="1000"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="EncryptData()" class="bckBtn">Encrypt</button>
            <button mat-raised-button (click)="DecryptData()" gap="20px" class="bckBtn">Decrypt</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="ClearData()">Clear</button>
        </mat-card-actions>
    </mat-card>
</div>