<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="dModelList" keyExpr="employee_type_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true"
        (onSelectionChanged)="onSelectionChanged($event)"
        >
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="50">
            <dxi-button hint="Edit selected Employee Type" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="employee_type_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="employee_type_code" [width]="150" caption="Employee Type"></dxi-column>
        <dxi-column dataField="employee_type_title" [width]="200" caption="Employee Type Title"></dxi-column>
        <!-- <dxi-column dataField="time_zone" [width]="200" caption="Time Zone"></dxi-column> -->
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>