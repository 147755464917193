import { Component } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LineClassService } from '../../../../../APIServices/master/weld/LineClassService';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../../util';
import { OnlyAlphanumericDirective } from '../../../../../directives/only-alphanumeric.directive';

@Component({
  selector: 'app-line-class-details',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule, OnlyAlphanumericDirective],
  templateUrl: './line-class-details.component.html',
  styleUrl: './line-class-details.component.scss'
})
export class LineClassDetailsComponent {
  selectedInfo: any;
  queryParams: any;
  project_id: any;
  bEditMode: boolean = false;
  lcForm: FormGroup;
  loggedInUser: any;
  sModelList: any;
  pModelList: any;

  constructor(private lineClassService: LineClassService, private router: Router,
    private route: ActivatedRoute) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.populateProject();
    await this.Index();
    await this.populateMaterialType();
  }

  async Index() {
    await this.lineClassService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['lineclass_id'];
      this.project_id = this.route.snapshot.queryParams['project_id'];

      if (this.queryParams != undefined) {
        this.bEditMode = true;

        (await this.lineClassService.getSelectedLineClass(this.queryParams, this.project_id)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];

          this.lineClassService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {
        this.bEditMode = false;
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
  }

  async populateMaterialType() {
    (await this.lineClassService.getActiveMaterialTypeList()).subscribe((apiResponse: any) => {
      this.sModelList = utility.extractJsonData(apiResponse, 0);
      if (this.selectedInfo != null && this.selectedInfo.length > 0)
        this.selectedInfo.material_type_id = this.selectedInfo[0].material_type_id;
    });
  }

  async populateProject() {
    (await this.lineClassService.getActiveProjectList()).subscribe((apiResponse: any) => {
      this.pModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  save() {
    this.lcForm.markAllAsTouched();
    const isFormValid = this.lcForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.lcForm.value['lineclass_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }
  }
  async createItem() {
    (await this.lineClassService.createLineClass(this.lcForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }
  async updateItem() {
    (await this.lineClassService.updateLineClass(this.lcForm.getRawValue())).subscribe((apiResponse: any) => {
      var iRes = utility.responseAlert(apiResponse);
      if (iRes) this.backtoList();
    });
  }
  backtoList() {
    this.router.navigate(['./lineclasslist']);
  }
  async initializeForm(lcInfo: any) {
    console.log(lcInfo);
    if (lcInfo != null && (lcInfo?.lineclass_id != undefined || lcInfo?.lineclass_id != null)) {
      this.lcForm = new FormGroup({
        lineclass_id: new FormControl(lcInfo?.lineclass_id),
        site_id: new FormControl(lcInfo?.site_id),
        project_id: new FormControl({ value: lcInfo?.project_id, disabled: this.bEditMode }, [Validators.required]),
        lineclass_code: new FormControl({ value: lcInfo?.lineclass_code, disabled: this.bEditMode }, [Validators.required]),
        lineclass_desc: new FormControl(lcInfo?.lineclass_desc, [Validators.required]),
        material_type_id: new FormControl(lcInfo?.material_type_id, [Validators.required]),
        pressure: new FormControl(lcInfo?.pressure),
        rt: new FormControl(lcInfo?.rt),
        ut: new FormControl(lcInfo?.ut),
        dp: new FormControl(lcInfo?.dp),
        ma: new FormControl(lcInfo?.ma),
        pa: new FormControl(lcInfo?.pa),
        is_active: new FormControl({value:lcInfo?.is_active,disabled: lcInfo?.is_active}),
        requester_id: new FormControl('')
      });
    }
    else {
      this.lcForm = new FormGroup({
        lineclass_id: new FormControl(null),
        site_id: new FormControl(lcInfo?.site_id),
        project_id: new FormControl(lcInfo?.project_id, [Validators.required]),
        lineclass_code: new FormControl({ value: '', disabled: false }, [Validators.required]),
        lineclass_desc: new FormControl('', [Validators.required]),
        material_type_id: new FormControl(null, [Validators.required]),
        pressure: new FormControl(null),
        rt: new FormControl(null),
        ut: new FormControl(null),
        dp: new FormControl(null),
        ma: new FormControl(null),
        pa: new FormControl(null),
        is_active: new FormControl({value:true,disabled: true}),
        requester_id: new FormControl('')
      });
    }
  }
}
